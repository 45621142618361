import {
  Card,
  CardBody,
  CardFooter,
  Center,
  Flex,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Messages from "./messages";
import Footer from "./footer";

const Conversation = ({
  messages,
  isLoading,
  handleSendMessage,
  inputMessage,
  setInputMessage,
  selectedUser,
}: any) => {
  return (
    <>
      <VStack
        w="full"
        maxH={{ base: "70vh", lg: "85vh" }}
        justify="space-between"
      >
        {selectedUser?._id ? (
          <>
            <Card
              w="full"
              border="none"
              boxShadow="none"
              borderRadius="24px"
              gap="0px !important"
              // py="2px"
            >
              <CardBody>
                <Messages messages={messages} isLoading={isLoading} />
              </CardBody>
              <CardFooter padding="5px 4px !important">
                <Footer
                  handleSendMessage={handleSendMessage}
                  inputMessage={inputMessage}
                  setInputMessage={setInputMessage}
                />
              </CardFooter>
            </Card>
          </>
        ) : (
          <>
            <Flex align="end" minH="40vh">
              <Text color="#646c9a" fontSize="20px" fontWeight="700">
                Please Select a Pet to Begin
              </Text>
            </Flex>
          </>
        )}
      </VStack>
    </>
  );
};

export default Conversation;
