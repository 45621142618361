import {
  Flex,
  InputGroup,
  VStack,
  HStack,
  Box,
  Button,
  Input,
  InputRightElement,
  Icon,
  Text,
} from "@chakra-ui/react";
import { AiOutlineSend } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Footer = ({ handleSendMessage, inputMessage, setInputMessage }: any) => {
  const navigate = useNavigate();
  return (
    <VStack w="full">
      <Flex alignItems="center" padding="0px" w="full" justify="space-between">
        <VStack w="full">
          <InputGroup height="64px" alignContent="center">
            <Input
              placeholder="Type a Message"
              border="1px solid #E5E4F9"
              borderRadius="24px"
              height="64px"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage(inputMessage);
                }
              }}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
            />
            <InputRightElement padding="30px 35px">
              <Button
                borderRadius="full"
                color="white"
                bg="#EC694E"
                colorScheme="orange"
                height="40px"
                width="40px"
                isDisabled={inputMessage.trim().length <= 0 ? true : false}
                onClick={() => handleSendMessage(inputMessage)}
              >
                <Icon as={AiOutlineSend} fontSize="20px" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </VStack>
      </Flex>
      <HStack w="full" align="end" justify="center">
        <Text fontSize={{ base: "12px", md: "13px" }} fontStyle="italic">
          Consult a local vet or use
          <b
            style={{ cursor: "pointer", margin: "0px 3px" }}
            onClick={() => navigate("/chat")}
          >
            Chat with IRL Vet
          </b>
          before starting treatments for accurate pet diagnosis.
        </Text>
      </HStack>
    </VStack>
  );
};

export default Footer;
