import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../interceptors";
import { usePetsStore } from "../../../store";
import { logo } from "../../../partial/images";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import ReactGA from "react-ga4";

import "../auth.css";

const Email = ({ setSignUpStepCount, setData }: any) => {
  const { fetchPets, petsData } = usePetsStore();
  const navigate = useNavigate();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const [validEmail, setValidEmail] = useState(false);
  const fbId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const emailCheck = (e: any) => {
    const mail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(mail);
    setValidEmail(isValid);

    isValid && setEmail(mail);
  };
  useEffect(() => {
    if (!isChecking) {
      if (petsData.length > 0) {
        navigate("/home");
      } else {
        navigate("/addpet");
      }
    }
  }, [isChecking, petsData]);
  const handleSubmit = () => {
    if (isTermsChecked && validEmail) {
      setIsLoading(true);

      useFetch
        .post(`/createEmail`, {
          enterEmail: email,
        })
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setData(res.data.data);
            setSignUpStepCount(2);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response && error.response.status === 409) {
            console.log("Conflict Error:", error.response.data.message);
            toast({
              title: "Error.",
              position: "top",
              description: `${error.response.data.message}`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            console.log("Error:", error);
          }
        });
    } else if (!validEmail) {
      toast({
        title: "Enter a valid Email",
        position: "top",
        description: ``,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      await useFetch
        .post(`/googleLogin`, {
          googleAccessToken: codeResponse.access_token,
        })
        .then(async (res) => {
          if (res.status === 200) {
            ReactGA.event({
              category: "User",
              action: "login",
              label: "User Logged In",
            });
            res.data.data &&
              localStorage.setItem("user", JSON.stringify(res.data.data));
            localStorage.setItem(
              "userToken",
              JSON.stringify(res.data.data.token)
            );
            await fetchPets();

            setIsChecking(false);

            toast({
              position: "top",
              title: `${res.data.message}`,
              description: "",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          toast({
            position: "top",
            title: `${error?.response?.data?.message}`,
            description: "",
            status: "error",
            duration: 3000,
            isClosable: false,
          });
        });
    },
  });
  const handleFacebookSuccessLogin = (response: any) => {
    console.log("handleFacebookSuccessLogin", response);
    useFetch
      .post(`/facebookLogin`, {
        userId: response?.userID,
      })
      .then(async (res) => {
        if (res.status === 200) {
          ReactGA.event({
            category: "User",
            action: "login",
            label: "User Logged In",
          });
          res.data.data &&
            localStorage.setItem("user", JSON.stringify(res.data.data));
          localStorage.setItem(
            "userToken",
            JSON.stringify(res.data.data.token)
          );
          await fetchPets();
          setIsChecking(false);

          toast({
            position: "top",
            title: `${res.data.message}`,
            description: "",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      })

      .catch((error) => {
        toast({
          position: "top",
          title: `${error?.response?.data?.message}`,
          description: "",
          status: "error",
          duration: 3000,
          isClosable: false,
        });
      });
  };
  const handlePrivacyPolicyClick = () => {
    window.open("/Privacy-Policy", "_blank");
  };
  const handleTermsClick = () => {
    window.open("/terms-services", "_blank");
  };

  return (
    <VStack flex={1} justify="center" mx="10px">
      <VStack
        align="center"
        border="1px solid #dadada"
        borderRadius="48px"
        height="auto"
        width="auto"
        padding={{ base: "10px 25px", lg: "72px 132px" }}
        gap="24px"
      >
        <VStack gap="10px" align="center">
          <Image src={logo} height="auto" w="80px" />
          <Text fontSize="26px" fontWeight="700" pt="5px">
            PetVet-AI
          </Text>
          <Text className="from-title">Create an Account 👨‍💻</Text>
          <Text className="form-desc">Let's go through a few simple steps</Text>
        </VStack>

        <VStack gap="16px">
          <FormControl>
            <FormLabel fontSize="12px" fontWeight="400">
              Email
            </FormLabel>
            <InputGroup
              bg="transparent"
              h="56px"
              borderRadius="16px"
              height="auto"
            >
              <InputLeftElement fontSize="20px" h="56px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M3.58519 5.417L8.40204 8.85837C9.35793 9.54129 10.642 9.54129 11.5979 8.85837L16.4148 5.417M2.40447 12.626C1.97626 10.9015 1.97626 9.09846 2.40447 7.37396C2.96502 5.11653 4.75499 3.37111 7.02544 2.86802L7.40367 2.78421C9.11381 2.40526 10.8862 2.40526 12.5963 2.78421L12.9745 2.86802C15.245 3.37111 17.0349 5.11653 17.5955 7.37396C18.0237 9.09846 18.0237 10.9015 17.5955 12.626C17.0349 14.8835 15.245 16.6289 12.9745 17.132L12.5963 17.2158C10.8861 17.5947 9.11381 17.5947 7.40367 17.2158L7.02544 17.132C4.75499 16.6289 2.96502 14.8835 2.40447 12.626Z"
                    stroke="#8B8D8F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </InputLeftElement>
              <Input
                h="56px"
                borderRadius="16px"
                isRequired={true}
                type="text"
                placeholder="name@example.com"
                onChange={emailCheck}
              />
              {validEmail && (
                <InputRightElement h="56px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2559 5.24408C17.5814 5.56951 17.5814 6.09715 17.2559 6.42259L8.08926 15.5893C7.76382 15.9147 7.23618 15.9147 6.91074 15.5893L2.74408 11.4226C2.41864 11.0972 2.41864 10.5695 2.74408 10.2441C3.06951 9.91864 3.59715 9.91864 3.92259 10.2441L7.5 13.8215L16.0774 5.24408C16.4028 4.91864 16.9305 4.91864 17.2559 5.24408Z"
                      fill="#22C55E"
                    />
                  </svg>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl fontSize="12px">
            <Flex align="center" w="full" gap={1}>
              <Checkbox
                onChange={(e) => setIsTermsChecked(e.target.checked)}
                me="2px"
              />

              <Text fontSize="13px" line-height="20px" letterSpacing="-0.12px">
                I Confirm I've Read and Agree to
              </Text>
              <Text className="finish-terms-link" onClick={handleTermsClick}>
                Terms of Service
              </Text>
              <Text fontSize="13px" line-height="20px" letterSpacing="-0.12px">
                and
              </Text>
              <Text
                className="finish-terms-link"
                onClick={handlePrivacyPolicyClick}
              >
                Privacy Policy
              </Text>
            </Flex>
          </FormControl>
          <Button
            bg="#8F4BF6"
            h="auto"
            w={{ base: "auto", md: "344px" }}
            p="18px 32px"
            color="white"
            borderRadius="20px"
            colorScheme="purple"
            type="submit"
            isLoading={isLoading}
            isDisabled={!isTermsChecked}
            onClick={handleSubmit}
          >
            Proceed
          </Button>
        </VStack>

        <HStack w="full" justify="space-evenly">
          <Divider orientation="horizontal" />
          <Text
            fontSize="12px"
            fontWeight="600"
            width="55%"
            textAlign="center"
            letterSpacing="-0.12px"
          >
            Or use social
          </Text>
          <Divider orientation="horizontal" />
        </HStack>
        <HStack>
          <Button
            bg="#F1F0F9"
            borderRadius="full"
            height="56px"
            width="56px"
            fontSize="24px"
            onClick={() => googleLogin()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                fill="#FAD826"
              />
              <path
                d="M3.15302 7.3455L6.43851 9.755C7.32752 7.554 9.48052 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.15902 2 4.82802 4.1685 3.15302 7.3455Z"
                fill="#DF4646"
              />
              <path
                d="M12 21.9999C14.583 21.9999 16.93 21.0114 18.7045 19.4039L15.6095 16.7849C14.5718 17.574 13.3038 18.0009 12 17.9999C9.39903 17.9999 7.19053 16.3414 6.35853 14.0269L3.09753 16.5394C4.75253 19.7779 8.11353 21.9999 12 21.9999Z"
                fill="#22C55E"
              />
              <path
                d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                fill="#1976D2"
              />
            </svg>
          </Button>
          <FacebookLogin
            appId={fbId ? fbId : ""}
            fields="name,email,picture"
            onSuccess={(response) => handleFacebookSuccessLogin(response)}
            onFail={() => {
              toast({
                position: "top",
                title: `Login Failed`,
                description: "",
                status: "error",
                duration: 3000,
                isClosable: false,
              });
            }}
            onProfileSuccess={(response) => {}}
            render={({ onClick }) => (
              <Button
                bg="#F1F0F9"
                borderRadius="full"
                height="56px"
                width="56px"
                fontSize="24px"
                onClick={onClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10.5"
                    fill="url(#paint0_linear_85_29313)"
                  />
                  <path
                    d="M15.9103 15.2112L16.3767 12.2476H13.4589V10.3252C13.4589 9.51428 13.8657 8.7233 15.1726 8.7233H16.5V6.20024C16.5 6.20024 15.2959 6 14.1452 6C11.7411 6 10.1712 7.4197 10.1712 9.98883V12.2476H7.5V15.2112H10.1712V22.3759C10.7075 22.458 11.2562 22.5 11.8151 22.5C12.374 22.5 12.9226 22.458 13.4589 22.3759V15.2112H15.9103Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_85_29313"
                      x1="12"
                      y1="1.5"
                      x2="12"
                      y2="22.4377"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#18ACFE" />
                      <stop offset="1" stop-color="#0163E0" />
                    </linearGradient>
                  </defs>
                </svg>
              </Button>
            )}
          />
        </HStack>
      </VStack>
      <Flex align="center" gap="4px" pt="40px" mb="10px">
        <Text fontSize="14px" fontWeight="400" lineHeight="24px">
          Already have an account?
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          cursor="pointer"
          onClick={() => navigate("/login")}
          _hover={{ textDecoration: "underline", fontWeight: "bold" }}
          lineHeight="20px"
          letterSpacing="-0.14px"
        >
          Login
        </Text>
      </Flex>
    </VStack>
  );
};

export default Email;
