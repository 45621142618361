import {
  Button,
  Flex,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { AiOutlineCheck } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import { useForm, SubmitHandler } from "react-hook-form";
import { MY_PET_LABEL } from "../../../partial/utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../../partial/utils/user";
import { storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useFetch } from "../../../interceptors";
import uuid from "react-uuid";
import { usePetsStore } from "../../../store";
import { noImageDog } from "../../../partial/images";

type Inputs = {
  petName: string;
  petAge: string;
  petType: string;
  breed: string;
};
const EditPet = () => {
  const currentUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const { updatePetsData } = usePetsStore();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;

  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState(data?.petImage);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  useEffect(() => {
    setValue("petName", data?.petName);
    setValue("petAge", data?.petAge);
    setValue("petType", data?.petType);
    setValue("breed", data?.breed);
  }, []);
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImageLoading(true);
    const file: any = event.target.files?.[0];
    if (!file) return;
    const imageId = uuid();
    const imageRef = ref(storage, `images/${currentUser.userId}/${imageId}`);

    try {
      await uploadBytes(imageRef, file).then(async (snapshot) => {
        await getDownloadURL(snapshot.ref).then(async (url: any) => {
          setImageLoading(false);
          setImageUrl(url as string);
        });
      });
    } catch (error) {
      setImageLoading(false);
      console.log("error", error);
    }
  };
  const onSubmit: SubmitHandler<Inputs> = (value) => {
    setIsLoading(true);
    const petId: any = data?._id;
    const BODY = {
      userId: currentUser?.user?._id,
      petName: value.petName,
      petAge: value.petAge,
      petType: value.petType,
      breed: value.breed,
      petImage: imageUrl,
    };

    useFetch
      .put(`/updatePetInfo/${petId}`, BODY)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          updatePetsData(res.data.data);
          toast({
            position: "top",
            title: `Pet Information Updated Successfully`,
            description: "",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        navigate("/home");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const handleIconButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDelete = () => {
    useFetch.delete(`/deletePet/${data?._id}`).then((res) => {
      if (res.status === 200) {
        updatePetsData(res.data.data);
        toast({
          position: "top",
          title: `Pet Deleted Successfully`,
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }

      navigate("/home");
    });
  };
  return (
    <>
      <Flex direction="column" w="full">
        <HStack w="full" justify="space-between" px="20px">
          <Text
            fontSize="24px"
            fontWeight="700"
            lineHeight="24px"
            letterSpacing="-0.24px"
          >
            Edit pet
          </Text>
          <IconButton
            aria-label="delete"
            color="black"
            height="48px"
            width="48px"
            borderRadius="full"
            onClick={handleDelete}
          >
            <Icon as={RiDeleteBinLine} fontSize="24px" />
          </IconButton>
        </HStack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack gap="20px" mt="5px">
            <VStack position="relative">
              <Image
                src={imageUrl ? imageUrl : noImageDog}
                height="158px"
                width="158px"
                borderRadius="full"
              />
              {imageLoading && (
                <Spinner
                  position="absolute"
                  top="10"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              )}
              <input
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <IconButton
                height="48px"
                width="48px"
                onClick={handleIconButtonClick}
                position="absolute"
                top="125"
                aria-label="Search database"
                borderRadius="full"
                bg="#EC694E"
                colorScheme="orange"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4ZM14.325 9.675L13.625 8.975L15.025 10.375L14.325 9.675Z"
                      fill="white"
                    />
                  </svg>
                }
              />
            </VStack>
            <VStack width={{ base: "auto", md: "327px" }}>
              <FormControl>
                <Text {...MY_PET_LABEL}>Name</Text>
                <Input
                  type="text"
                  borderRadius="16px"
                  height="56px"
                  {...register("petName")}
                />
              </FormControl>
              <FormControl>
                <Text {...MY_PET_LABEL}>Age</Text>
                <Input
                  type="text"
                  borderRadius="16px"
                  height="56px"
                  {...register("petAge")}
                />
              </FormControl>
              <FormControl>
                <Text {...MY_PET_LABEL}>Pet</Text>
                <Input
                  type="text"
                  borderRadius="16px"
                  height="56px"
                  {...register("petType")}
                />
              </FormControl>
              <FormControl>
                <Text {...MY_PET_LABEL}>Breed</Text>
                <Input
                  type="text"
                  borderRadius="16px"
                  height="56px"
                  {...register("breed")}
                />
              </FormControl>
            </VStack>
            <Button
              type="submit"
              height="54px"
              width="253px"
              borderRadius="59px"
              border="1px solid #8F4BF6"
              bg="#8F4BF6"
              colorScheme="purple"
              isLoading={isLoading}
            >
              <Flex justify="center" align="center" gap="10px">
                <Icon as={AiOutlineCheck} />
                <Text>SAVE</Text>
              </Flex>
            </Button>
          </VStack>
        </form>
      </Flex>
    </>
  );
};

export default EditPet;
