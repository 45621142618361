import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "../../auth.css";
import { useNavigate } from "react-router-dom";

import { secure } from "../../../../partial/images";
import { useFetch } from "../../../../interceptors";

const EmailVerification = ({ setForgotStepCount, setData }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const emailCheck = (e: any) => {
    const mail = e.target.value;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(mail);
    setValidEmail(isValid);
    {
      isValid && setEmail(mail);
    }
  };
  const handleSubmit = () => {
    if (email.length < 1) {
      setErrorMessage("* Email is required.");
      setShowError(true);
    } else if (email.length > 1 && !validEmail) {
      setErrorMessage("Invalid Email .");
      setShowError(true);
    } else if (validEmail && email.length > 1) {
      setShowError(false);
      setIsLoading(true);
      useFetch
        .post(`/emailForgotPassword`, {
          email: email,
        })
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setData(res.data.data);
            setForgotStepCount(2);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            console.log("Conflict Error:", error.response.data.message);
            toast({
              title: `${error.response.data.message}`,
              position: "top",
              description: ``,
              status: "error",
              duration: 4000,
              isClosable: true,
            });
          } else {
            console.log("Error:", error);
          }
        });
    }
  };
  const redirectToEmail = () => {
    window.location.href = "mailto:email-privacy@petvet.ai";
  };
  return (
    <VStack flex={1} justify="center" mx="10px">
      <VStack
        align="center"
        border="1px solid #dadada"
        borderRadius="48px"
        height="auto"
        width="auto"
        padding={{ base: "10px 25px", lg: "72px 132px" }}
        gap="24px"
      >
        <Image src={secure} height="136px" width="136px" borderRadius="full" />
        <VStack>
          <Text className="from-title">Forgot Password ?</Text>
          <Text className="form-desc">
            Enter email address that you used when registering
          </Text>
        </VStack>

        <VStack gap="16px">
          <FormControl>
            <FormLabel fontSize="12px" fontWeight="400">
              Email
            </FormLabel>
            <InputGroup bg="transparent" h="56px" borderRadius="16px">
              <InputLeftElement fontSize="20px" h="56px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M3.58519 5.417L8.40204 8.85837C9.35793 9.54129 10.642 9.54129 11.5979 8.85837L16.4148 5.417M2.40447 12.626C1.97626 10.9015 1.97626 9.09846 2.40447 7.37396C2.96502 5.11653 4.75499 3.37111 7.02544 2.86802L7.40367 2.78421C9.11381 2.40526 10.8862 2.40526 12.5963 2.78421L12.9745 2.86802C15.245 3.37111 17.0349 5.11653 17.5955 7.37396C18.0237 9.09846 18.0237 10.9015 17.5955 12.626C17.0349 14.8835 15.245 16.6289 12.9745 17.132L12.5963 17.2158C10.8861 17.5947 9.11381 17.5947 7.40367 17.2158L7.02544 17.132C4.75499 16.6289 2.96502 14.8835 2.40447 12.626Z"
                    stroke="#8B8D8F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </InputLeftElement>
              <Input
                type="text"
                placeholder="name@example.com"
                onChange={emailCheck}
                h="56px"
                borderRadius="16px"
              />
              {validEmail && (
                <InputRightElement h="56px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2559 5.24408C17.5814 5.56951 17.5814 6.09715 17.2559 6.42259L8.08926 15.5893C7.76382 15.9147 7.23618 15.9147 6.91074 15.5893L2.74408 11.4226C2.41864 11.0972 2.41864 10.5695 2.74408 10.2441C3.06951 9.91864 3.59715 9.91864 3.92259 10.2441L7.5 13.8215L16.0774 5.24408C16.4028 4.91864 16.9305 4.91864 17.2559 5.24408Z"
                      fill="#22C55E"
                    />
                  </svg>
                </InputRightElement>
              )}
            </InputGroup>
            {showError && (
              <Text textAlign="center" color="red" fontSize="12px" pt="3px">
                {errorMessage}
              </Text>
            )}
          </FormControl>
          <Button
            bg="#8F4BF6"
            h="auto"
            w={{ base: "auto", md: "344px" }}
            p="18px 32px"
            color="white"
            borderRadius="20px"
            colorScheme="purple"
            type="submit"
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Recover Password
          </Button>
        </VStack>
      </VStack>
      <Flex align="center" gap="4px" pt="40px" mb="10px">
        <Text fontSize="14px" fontWeight="400">
          Need help?
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          onClick={redirectToEmail}
          color="#090B0C"
          letterSpacing="-0.14px"
          lineHeight="20px"
          cursor="pointer"
          _hover={{ color: "blue" }}
        >
          Contact Support
        </Text>
      </Flex>
    </VStack>
  );
};

export default EmailVerification;
