import { ReactNode } from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { SidebarContent } from "./partials";
import Header from "../header";
import Footer from "../footer";
import { useLocation } from "react-router-dom";

export default function Sidebar({ children }: { children: ReactNode }) {
  const location = useLocation();
  const isAddPetRoute = location.pathname === "/addpet";
  const isLoginRoute = location.pathname === "/login";
  const isSignUpRoute = location.pathname === "/signup";
  return (
    <Box h="100vh" background="white">
      {!(isAddPetRoute || isLoginRoute || isSignUpRoute) && <SidebarContent />}
      <Flex
        direction="column"
        ml={{
          base: "10px",
          lg: !(isAddPetRoute || isLoginRoute || isSignUpRoute)
            ? "331px"
            : "0px",
        }}
        mr="10px"
        pt="16px"
        h="100vh"
        maxH="100vh"
      >
        {!(isAddPetRoute || isLoginRoute || isSignUpRoute) && <Header />}
        <VStack w="full" h="full" justify="space-between">
          {children}
          {!(isAddPetRoute || isLoginRoute || isSignUpRoute) && <Footer />}
        </VStack>
      </Flex>
    </Box>
  );
}
