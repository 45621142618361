import { useEffect, useRef } from "react";

const PetInsuranceQuote = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const innerHTML = `<div id="pet-vet-app"></div>
    <script>

        QuoteEngine.setOptions({
            targetId: "pet-vet-app",
            redirectUrl: "https://quote.petinsurer.com/quote/",
            baseUrl: "https://quote.petinsurer.com/",
            urlParam: {
              source: "IHDogs-Quote-Widget",
              utm_source: "IHDogs-Quote-Widget",
              utm_medium: "Website",
              utm_campaign: "pet-vet-app",
              utm_content: "",
              utm_term: "",
            },
            refCode: "ihdogs",
          });
            QuoteEngine.init();
        
    </script>`;

  const fragment = document.createRange().createContextualFragment(innerHTML);
  useEffect(() => {
    if (divRef && divRef.current) {
      divRef.current.appendChild(fragment);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        maxHeight: "80vh",
        margin: "0px !important",
      }}
      ref={divRef}
    />
  );
};

export default PetInsuranceQuote;
