import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  PinInput,
  PinInputField,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../../auth.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { sendMessage } from "../../../../partial/images";
import { useFetch } from "../../../../interceptors";

const OtpVerification = ({ setForgotStepCount, data }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [seconds, setSeconds] = useState(60);
  const [showTimer, setShowTimer] = useState(true);
  const [otp, setOtp] = useState("");

  const redirectToEmail = () => {
    window.location.href = "mailto:email-privacy@petvet.ai";
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds < 1) {
        setShowTimer(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const remainingSeconds = seconds % 60;
  const handleSubmit = () => {
    setIsLoading(true);
    useFetch
      .post(`/emailVerification`, {
        enterOtp: otp,
        _id: data?.userid,
      })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setForgotStepCount(3);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          console.log("Conflict Error:", error.response.data.message);
          toast({
            title: "Error.",
            position: "top",
            description: `${error.response.data.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.log("Error:", error);
        }
      });
  };

  const handleResendOTP = () => {
    setShowTimer(true);
    setSeconds(60);
    setOtp("");
    useFetch
      .post(`/otpResend`, {
        _id: data?.userid,
      })
      .then((res) => {})
      .catch((error) => {
        if (
          (error.response && error.response.status === 409) ||
          error.response.status === 498
        ) {
          console.log("Conflict Error:", error.response.data.message);
          toast({
            title: `${error.response.data.message}`,
            position: "top",
            description: ``,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.log("Error:", error);
        }
      });
  };
  return (
    <VStack flex={1} justify="center" mx="10px">
      <VStack
        align="center"
        border="1px solid #dadada"
        borderRadius="48px"
        height="auto"
        width="auto"
        padding={{ base: "10px 25px", lg: "72px 132px" }}
        gap="24px"
      >
        <Image
          src={sendMessage}
          height="136px"
          width="136px"
          borderRadius="full"
        />
        <VStack>
          <Text className="from-title">Email Verification</Text>
          <Text className="form-desc">
            A code was sent to {data?.userEmail}
          </Text>
          <Text
            className="form-desc"
            onClick={() => setForgotStepCount(1)}
            cursor="pointer"
            fontWeight="500 !important"
          >
            Change email
          </Text>
        </VStack>

        <VStack gap="16px">
          <HStack>
            <PinInput otp onChange={(value) => setOtp(value)}>
              <PinInputField
                padding="16px 20px"
                height="56px"
                width="54px"
                borderRadius="16px"
              />
              <PinInputField
                padding="16px 20px"
                height="56px"
                width="54px"
                borderRadius="16px"
              />
              <PinInputField
                padding="16px 20px"
                height="56px"
                width="54px"
                borderRadius="16px"
              />
              <PinInputField
                padding="16px 20px"
                height="56px"
                width="54px"
                borderRadius="16px"
              />
              <PinInputField
                padding="16px 20px"
                height="56px"
                width="54px"
                borderRadius="16px"
              />
              <PinInputField
                padding="16px 20px"
                height="56px"
                width="54px"
                borderRadius="16px"
              />
            </PinInput>
          </HStack>
          <Button
            bg="#8F4BF6"
            h="auto"
            w={{ base: "auto", md: "344px" }}
            p="18px 32px"
            color="white"
            borderRadius="20px"
            colorScheme="purple"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Recover Password
          </Button>
          {showTimer ? (
            <Flex align="center" gap="4px">
              <Text fontSize="14px" fontWeight="400">
                Resend code in
              </Text>
              <Text fontSize="14px" fontWeight="500" color="#B1AED3">
                00:{remainingSeconds}
              </Text>
            </Flex>
          ) : (
            <Flex align="center" gap="4px">
              <Text fontSize="14px" fontWeight="400">
                Didn’t receive a code?
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                cursor="pointer"
                onClick={handleResendOTP}
                _hover={{ textDecoration: "underline", fontWeight: "bold" }}
              >
                Resend
              </Text>
            </Flex>
          )}
        </VStack>
      </VStack>
      <Flex align="center" gap="4px" pt="40px" mb="10px">
        <Text fontSize="14px" fontWeight="400">
          Need help?
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          onClick={redirectToEmail}
          color="#090B0C"
          letterSpacing="-0.14px"
          lineHeight="20px"
          cursor="pointer"
          _hover={{ color: "blue" }}
        >
          Contact Support
        </Text>
      </Flex>
    </VStack>
  );
};

export default OtpVerification;
