import React, { useState } from "react";
import UpgradeStep2 from "./proPlan";
import UpgradeStep3 from "./step3";
import { Flex } from "@chakra-ui/react";

const Steps = () => {
  const [stepCount, setStepCount] = useState(1);
  return (
    <>
      <Flex mt="10px">
        {stepCount === 1 && <UpgradeStep2 setStepCount={setStepCount} />}
        {stepCount === 2 && <UpgradeStep3 />}
      </Flex>
    </>
  );
};

export default Steps;
