import { Flex, Image, Spinner, Text, VStack } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import moment from "moment";

import { aiLogo, aiVet, userLogo, vetDoc } from "../../../../partial/images";
import BeatLoader from "../../../../partial/beatLoader/BeatLoader";
import ReactLinkify from "react-linkify";

const Messages = ({ messages, isLoading }: any) => {
  const date = new Date();
  const AlwaysScrollToBottom = () => {
    const elementRef: any = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  return (
    <>
      <Flex
        w="100%"
        overflowY="scroll"
        flexDirection="column"
        px="2px"
        py="3"
        minH={{ base: "51vh", lg: "66vh" }}
        maxH={{ base: "53vh", lg: "66vh" }}
        position="relative"
      >
        {isLoading && (
          <Flex
            minH={{ base: "51vh", lg: "66vh" }}
            justify="center"
            align="center"
            left="275"
            position="absolute"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
        <Flex direction="column">
          {messages.map((item: any, index: any) => {
            let stickyDate = null;
            if (
              index === 0 ||
              moment(item.time).format("MMMM Do") !==
                moment(messages[index - 1].time).format("MMMM Do")
            ) {
              stickyDate = (
                <Flex
                  justify="center"
                  align="center"
                  w="full"
                  mb="24px"
                  my="10px"
                >
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    letterSpacing="-0.12px"
                    lineHeight="16px"
                  >
                    {moment(item.time).format("MMMM Do")}
                  </Text>
                </Flex>
              );
            }
            if (item.role === "user") {
              return (
                <>
                  <Flex key={index} justify="flex-end" align="start" gap="12px">
                    <VStack
                      w="auto"
                      align="end"
                      bg="#EEF9F2"
                      minW="auto"
                      my="1"
                      p="12px 16px"
                      gap="4px"
                      fontSize={{ base: "13px", "2xl": "14px" }}
                      borderRadius="16px 0px 16px 16px"
                      maxW="350px"
                    >
                      <Flex color="#6c7293">
                        <Text fontWeight="bold">{item.content}</Text>
                      </Flex>
                      <Text
                        color="#74788d"
                        fontSize={{ base: "11px", "2xl": "12px" }}
                      >
                        {item.time ? moment(item.time).format("LT") : ""}
                      </Text>
                    </VStack>
                    <Image src={userLogo} height="48px" width="48px" />
                  </Flex>
                </>
              );
            } else {
              return (
                <>
                  {stickyDate}
                  <Flex
                    justify="flex-start"
                    align="start"
                    gap="12px"
                    key={index}
                  >
                    <Image
                      src={vetDoc}
                      borderRadius="full"
                      height="48px"
                      width="48px"
                    />
                    <VStack
                      w="auto"
                      align="end"
                      bg="#F1F0F9"
                      minW="auto"
                      my="1"
                      p="12px 16px"
                      gap="4px"
                      fontSize={{ base: "13px", "2xl": "14px" }}
                      borderRadius="0px 16px 16px 16px"
                      maxW="350px"
                    >
                      <Flex color="#6c7293">
                        <Text fontWeight="bold">
                          {item.role === "typing" ? (
                            <BeatLoader />
                          ) : (
                            <>
                              <ReactLinkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    href={decoratedHref}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={key}
                                    style={{ color: "#2F89FC" }}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                <Text
                                  fontWeight="bold"
                                  maxW={["29vh", "50vh"]}
                                  whiteSpace="pre-line"
                                >
                                  {item.content}
                                </Text>
                              </ReactLinkify>
                            </>
                          )}
                        </Text>
                      </Flex>
                      <Text
                        textAlign="end"
                        color="#74788d"
                        fontSize={{ base: "11px", "2xl": "12px" }}
                      >
                        {item.time ? moment(item.time).format("LT") : ""}
                      </Text>
                    </VStack>
                  </Flex>
                </>
              );
            }
          })}
        </Flex>
        <AlwaysScrollToBottom />
      </Flex>
    </>
  );
};

export default Messages;
