import { Button, Flex, Icon, Image } from "@chakra-ui/react";
import { pharmacyCard } from "../../../partial/images";
import { AiOutlineDownload } from "react-icons/ai";
const RxInsuranceCard = () => {
  const imageURl = pharmacyCard;
  const handleDownloadClick = () => {
    // Create an anchor element
    const link = document.createElement("a");
    link.href = imageURl;
    link.download = "pharmacy-savings-card.jpg";
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };
  return (
    <Flex
      direction="column"
      border="1px solid #F1F0F9"
      borderRadius="24px"
      w="full"
      py="3"
      align="center"
      gap={4}
    >
      <Flex w="full" justify="end" px="8">
        <Icon
          as={AiOutlineDownload}
          color="white"
          height="40px"
          width="40px"
          padding="6px"
          bg="#8F4BF6"
          borderRadius="full"
          cursor="pointer"
          onClick={handleDownloadClick}
        />
      </Flex>
      <Flex>
        <Image
          src={imageURl}
          // height="300px"
          // width="500px"
          width={{ base: "auto", lg: "1225px" }}
          height={{ base: "auto", lg: "700px" }}
        />
      </Flex>
    </Flex>
  );
};

export default RxInsuranceCard;
