const MODEL = "gpt-4-1106-preview";
const MAX_TOKEN = 400;
const TEMPERATURE = 0.4;
const TOP_P = 1.0;
const N = 1;

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Accept", "application/json");
myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_AI_API_KEY}`);

export const hitChatGpt = async (message: any) => {
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      model: MODEL,
      max_tokens: MAX_TOKEN,
      temperature: TEMPERATURE,
      top_p: TOP_P,
      n: N,
      stream: false,
      presence_penalty: 0,
      frequency_penalty: 0,
      messages: message,
    }),
  });
  return response;
};
