import { Box, Flex, Icon, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "../auth.css";
import Email from "./email";
import Emailverify from "./emailVerify";
import FinishRegistration from "./finishRegistration";
import { LiaStarSolid } from "react-icons/lia";
import {
  group1,
  group2,
  group3,
  group4,
  group5,
  image,
} from "../../../partial/images";
import { Carousel } from "react-responsive-carousel";
type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};
const resolver: Resolver<FormValues> = async (values) => {
  return {
    values: values.email ? values : {},
    errors: !values.email
      ? {
          email: {
            type: "required",
            message: "This is required.",
          },
        }
      : {},
  };
};
const SignUp = () => {
  const navigate = useNavigate();
  const [passwordMathc, setPasswordMathc] = useState(true);
  const ImageData = [
    {
      image: image,
      review: `"I’m very pleased with the answer and appreciate the help. I’ll be sure to Tell everyone about your site! Thank you."`,
      by: "Jan R",
      id: 1,
    },
    {
      image: group1,
      review: `"I needed a timely answer for my pup and PetVet was absolutely amazing. Thank you so much."`,
      by: "Mike B",
      id: 2,
    },
    {
      image: group2,
      review: `"It is incredible that this resource is free. I needed help and got it - all in less than 1 minute. Thank You So Much!!"`,
      by: "Trisha M",
      id: 3,
    },
  ];
  const [validEmail, setValidEmail] = useState(false);
  const [data, setData] = useState<any>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver });
  const onSubmit = handleSubmit((data) => {
    data.password === data.confirmPassword
      ? setPasswordMathc(true)
      : setPasswordMathc(false);
  });

  const emailCheck = (e: any) => {
    const email = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setValidEmail(isValid);
  };
  const [signUpStepCount, setSignUpStepCount] = useState(1);

  return (
    <>
      <Flex
        w="full"
        direction={{ base: "column-reverse", lg: "row" }}
        // maxH="100vh"
        h={{ base: "auto", lg: "100vh" }}
        align="center"
        gap={{ base: "20px", lg: "10px" }}
      >
        <VStack
          w={{ base: "full", lg: "544px" }}
          className="flagWidth"
          h={{ base: "auto", lg: "100vh" }}
          bg="#F1F0F9"
          justify="space-evenly"
          gap="20px"
          py="50px"
        >
          <Box className="carousel-width">
            <Carousel
              showArrows={false}
              showStatus={false}
              autoPlay={true}
              infiniteLoop={true}
            >
              {ImageData.map((data) => (
                <VStack key={data?.id} pb="50px">
                  <Image
                    src={data?.image}
                    height="317px"
                    width={{ base: "300px", md: "327px" }}
                  />
                  <VStack gap="16px" width={{ base: "300px", md: "400px" }}>
                    <Text fontSize="16px" fontWeight="400" textAlign="center">
                      {data?.review}
                    </Text>

                    <Flex gap="8px">
                      {[1, 2, 3, 4, 5].map((data) => (
                        <Icon as={LiaStarSolid} color="#DFA600" />
                      ))}
                    </Flex>
                    <Text
                      fontSize="16px"
                      fontWeight="700"
                      color="#737098"
                      textAlign="center"
                    >
                      {data?.by}
                    </Text>
                  </VStack>
                </VStack>
              ))}
            </Carousel>
          </Box>
        </VStack>
        <Flex w="full" mt={{ base: "40px", lg: "0" }}>
          {signUpStepCount === 1 && (
            <Email setData={setData} setSignUpStepCount={setSignUpStepCount} />
          )}
          {signUpStepCount === 2 && (
            <Emailverify data={data} setSignUpStepCount={setSignUpStepCount} />
          )}
          {signUpStepCount === 3 && <FinishRegistration data={data} />}
        </Flex>
      </Flex>
    </>
  );
};

export default SignUp;
