import {
  Button,
  ButtonGroup,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { HEADER_BUTTON } from "../../partial/utils/constants";
import { Link, useNavigate } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Sidebar from "../sidebar";
import { SidebarContent } from "../sidebar/partials";
import HamburgerSidebar from "./hamburger";
const Header = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("user");
    navigate("/login");
  };
  return (
    <>
      <Flex align="center" w="full" gap="5px">
        <Flex alignItems="center" display={["flex", "flex", "flex", "none"]}>
          <IconButton
            aria-label="hamburger"
            height="80px"
            width="80px"
            padding="10px"
            borderRadius="full"
            bg="#F1F0F9"
            colorScheme="white"
            onClick={onOpen}
            ref={btnRef}
          >
            <Icon as={BiDotsVerticalRounded} fontSize="35px" color="#8F4BF6" />
          </IconButton>
        </Flex>
        <Flex
          w="full"
          justify="space-between"
          bg="#F1F0F9"
          borderRadius="50px"
          height={{ base: "auto", lg: "auto" }}
          align="center"
          p="15px 20px"
          wrap="wrap"
          mb="8px"
        >
          <Flex display={{ base: "none", lg: "flex" }}>
            <Button
              border="1px solid #B1AED3"
              leftIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 8.66658H8.66667V11.9999C8.66667 12.3666 8.36667 12.6666 8 12.6666C7.63334 12.6666 7.33334 12.3666 7.33334 11.9999V8.66658H4C3.63334 8.66658 3.33334 8.36658 3.33334 7.99992C3.33334 7.63325 3.63334 7.33325 4 7.33325H7.33334V3.99992C7.33334 3.63325 7.63334 3.33325 8 3.33325C8.36667 3.33325 8.66667 3.63325 8.66667 3.99992V7.33325H12C12.3667 7.33325 12.6667 7.63325 12.6667 7.99992C12.6667 8.36658 12.3667 8.66658 12 8.66658Z"
                    fill="black"
                  />
                </svg>
              }
              borderRadius="44px"
              w="157px"
              height="60px"
              {...HEADER_BUTTON}
              onClick={() => navigate("/addpet")}
            >
              Add New Pet
            </Button>
          </Flex>
          <Flex display={{ base: "none", md: "flex", lg: "none" }}>
            <Button
              size="sm"
              border="1px solid #B1AED3"
              leftIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 8.66658H8.66667V11.9999C8.66667 12.3666 8.36667 12.6666 8 12.6666C7.63334 12.6666 7.33334 12.3666 7.33334 11.9999V8.66658H4C3.63334 8.66658 3.33334 8.36658 3.33334 7.99992C3.33334 7.63325 3.63334 7.33325 4 7.33325H7.33334V3.99992C7.33334 3.63325 7.63334 3.33325 8 3.33325C8.36667 3.33325 8.66667 3.63325 8.66667 3.99992V7.33325H12C12.3667 7.33325 12.6667 7.63325 12.6667 7.99992C12.6667 8.36658 12.3667 8.66658 12 8.66658Z"
                    fill="black"
                  />
                </svg>
              }
              borderRadius="44px"
              w="auto"
              height="60px"
              {...HEADER_BUTTON}
              onClick={() => navigate("/addpet")}
            >
              Add New Pet
            </Button>
          </Flex>
          <Flex display={{ base: "flex", md: "none" }}>
            <Button
              size="sm"
              border="1px solid #B1AED3"
              leftIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 8.66658H8.66667V11.9999C8.66667 12.3666 8.36667 12.6666 8 12.6666C7.63334 12.6666 7.33334 12.3666 7.33334 11.9999V8.66658H4C3.63334 8.66658 3.33334 8.36658 3.33334 7.99992C3.33334 7.63325 3.63334 7.33325 4 7.33325H7.33334V3.99992C7.33334 3.63325 7.63334 3.33325 8 3.33325C8.36667 3.33325 8.66667 3.63325 8.66667 3.99992V7.33325H12C12.3667 7.33325 12.6667 7.63325 12.6667 7.99992C12.6667 8.36658 12.3667 8.66658 12 8.66658Z"
                    fill="black"
                  />
                </svg>
              }
              borderRadius="44px"
              w="auto"
              height="60px"
              {...HEADER_BUTTON}
              fontSize="13px"
              onClick={() => navigate("/addpet")}
            >
              New Pet
            </Button>
          </Flex>
          <HStack gap="32px" wrap="wrap" align="center">
            <Flex display={{ base: "none", lg: "flex" }}>
              <Link to="/faqs">
                <Text
                  color="#272655"
                  fontSize="24px"
                  fontWeight="700"
                  lineHeight="32px"
                  letterSpacing="-0.192px"
                >
                  FAQs
                </Text>
              </Link>
            </Flex>
            <ButtonGroup gap="5px">
              {/* <Button
              leftIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M11 15H6L13 1V9H18L11 23V15Z" fill="white" />
                </svg>
              }
              borderRadius="20px"
              color="white"
              height="48px"
              bg="#EC694E"
              w="161px"
              colorScheme="tomato"
              onClick={() => navigate("/upgrade")}
            >
              Upgrade to Pro
            </Button> */}
              <Flex display={{ base: "none", lg: "flex" }}>
                <Button
                  leftIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.325 16.275C15.1417 16.0583 15.05 15.8127 15.05 15.538C15.05 15.2633 15.1417 15.034 15.325 14.85L17.175 13H10C9.71667 13 9.479 12.904 9.287 12.712C9.095 12.52 8.99934 12.2827 9 12C9 11.7167 9.096 11.479 9.288 11.287C9.48 11.095 9.71734 10.9993 10 11H17.175L15.325 9.15C15.125 8.95 15.025 8.71234 15.025 8.437C15.025 8.16167 15.125 7.92434 15.325 7.725C15.5083 7.525 15.7377 7.425 16.013 7.425C16.2883 7.425 16.5173 7.51667 16.7 7.7L20.3 11.3C20.4 11.4 20.471 11.5083 20.513 11.625C20.555 11.7417 20.5757 11.8667 20.575 12C20.575 12.1333 20.5543 12.2583 20.513 12.375C20.4717 12.4917 20.4007 12.6 20.3 12.7L16.7 16.3C16.4833 16.5167 16.2457 16.6127 15.987 16.588C15.7283 16.5633 15.5077 16.459 15.325 16.275ZM5 21C4.45 21 3.979 20.804 3.587 20.412C3.195 20.02 2.99934 19.5493 3 19V5C3 4.45 3.196 3.979 3.588 3.587C3.98 3.195 4.45067 2.99934 5 3H11C11.2833 3 11.521 3.096 11.713 3.288C11.905 3.48 12.0007 3.71733 12 4C12 4.28333 11.904 4.521 11.712 4.713C11.52 4.905 11.2827 5.00067 11 5H5V19H11C11.2833 19 11.521 19.096 11.713 19.288C11.905 19.48 12.0007 19.7173 12 20C12 20.2833 11.904 20.521 11.712 20.713C11.52 20.905 11.2827 21.0007 11 21H5Z"
                        fill="white"
                      />
                    </svg>
                  }
                  borderRadius="20px"
                  color="white"
                  height="48px"
                  bg="#8F4BF6"
                  w="128px"
                  colorScheme="purple"
                  onClick={handleLogout}
                >
                  Log Out
                </Button>
              </Flex>
              <Flex display={{ base: "flex", lg: "none" }}>
                <Button
                  borderRadius="20px"
                  color="white"
                  height="48px"
                  bg="#8F4BF6"
                  w="auto"
                  fontSize="13px"
                  colorScheme="purple"
                  onClick={handleLogout}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.325 16.275C15.1417 16.0583 15.05 15.8127 15.05 15.538C15.05 15.2633 15.1417 15.034 15.325 14.85L17.175 13H10C9.71667 13 9.479 12.904 9.287 12.712C9.095 12.52 8.99934 12.2827 9 12C9 11.7167 9.096 11.479 9.288 11.287C9.48 11.095 9.71734 10.9993 10 11H17.175L15.325 9.15C15.125 8.95 15.025 8.71234 15.025 8.437C15.025 8.16167 15.125 7.92434 15.325 7.725C15.5083 7.525 15.7377 7.425 16.013 7.425C16.2883 7.425 16.5173 7.51667 16.7 7.7L20.3 11.3C20.4 11.4 20.471 11.5083 20.513 11.625C20.555 11.7417 20.5757 11.8667 20.575 12C20.575 12.1333 20.5543 12.2583 20.513 12.375C20.4717 12.4917 20.4007 12.6 20.3 12.7L16.7 16.3C16.4833 16.5167 16.2457 16.6127 15.987 16.588C15.7283 16.5633 15.5077 16.459 15.325 16.275ZM5 21C4.45 21 3.979 20.804 3.587 20.412C3.195 20.02 2.99934 19.5493 3 19V5C3 4.45 3.196 3.979 3.588 3.587C3.98 3.195 4.45067 2.99934 5 3H11C11.2833 3 11.521 3.096 11.713 3.288C11.905 3.48 12.0007 3.71733 12 4C12 4.28333 11.904 4.521 11.712 4.713C11.52 4.905 11.2827 5.00067 11 5H5V19H11C11.2833 19 11.521 19.096 11.713 19.288C11.905 19.48 12.0007 19.7173 12 20C12 20.2833 11.904 20.521 11.712 20.713C11.52 20.905 11.2827 21.0007 11 21H5Z"
                      fill="white"
                    />
                  </svg>
                </Button>
              </Flex>
            </ButtonGroup>
          </HStack>
        </Flex>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg="#F1F0F9">
          <DrawerCloseButton />

          <HamburgerSidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Header;
