import { Button, Flex, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { lightning } from "../../../partial/images";
import "./step3.css";
const UpgradeStep3 = () => {
  return (
    <>
      <Flex justify="center" w="full">
        <VStack
          align="center"
          gap="32px"
          padding="44px"
          borderRadius="40px"
          border="1px solid #dadada"
        >
          <VStack gap="24px" w="327px">
            <Image src={lightning} height="72px" width="72px" />
            <VStack gap="8px">
              <Text className="text-title">Hello</Text>
              <Text className="text-des">
                Thank you for subscribing to Pro!
              </Text>
            </VStack>
            <VStack
              borderRadius="20px"
              border="1px solid #B1AED3"
              padding="24px"
              gap="16px"
              align="start"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 28C9.372 28 4 22.628 4 16C4 9.372 9.372 4 16 4C22.628 4 28 9.372 28 16C28 22.628 22.628 28 16 28Z"
                  fill="#2FAF62"
                />
                <path
                  d="M21.3334 13.333L14.6667 19.9997L10.6667 15.9997"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Stack w="279px">
                <Text className="text-title2">
                  Pro subscription: $24.99 / year.
                </Text>
                <Text className="text-des2">
                  Payment is charged through Apple.
                </Text>
              </Stack>
            </VStack>
          </VStack>
          <Button
            width="327px"
            height="auto"
            padding="18px 20px"
            borderRadius="20px"
            bg="#EC694E"
            color="white"
            fontSize="16px"
            letterSpacing="-0.16px"
            fontFamily="Brlow"
            lineHeight="24px"
          >
            Cancel Subscription
          </Button>
        </VStack>
      </Flex>
    </>
  );
};

export default UpgradeStep3;
