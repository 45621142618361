import {
  Flex,
  OrderedList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import "../privacy/privacy.css";
const TermsOfServices = () => {
  const TermsData = [
    {
      title: "Acceptance of Terms",
    },
    {
      title: "Account Creation and Security",
    },
    {
      title: "User Responsibilities",
    },
    {
      title: "Pet Profile",
    },
    {
      title: "Use of AI Chat",
    },
    {
      title: "Intellectual Property",
    },
    {
      title: "Changes to Terms",
    },
    {
      title: "Contact",
    },
    {
      title: " Governing Law",
    },
  ];
  const redirectToEmail = () => {
    window.location.href = "mailto:email-privacy@petvet.ai";
  };
  return (
    <>
      <Tabs w="full" variant="unstyled">
        <Flex
          direction={{ base: "column", md: "row" }}
          width="full"
          minH="85vh"
          align="start"
          border="1px solid #E5E4F9"
          borderRadius="22px"
        >
          <Flex
            direction="column"
            align="start"
            width={{ base: "auto", md: "250px" }}
            bg="#F1F0F9"
            borderRadius="22px"
            minH={{ base: "auto", md: "85vh" }}
            padding="20px 15px"
          >
            <Text
              fontSize={{ base: "14px", md: "24px" }}
              fontWeight="500"
              color="#737098"
            >
              Terms of Service
            </Text>
            <TabList borderBottom="none">
              <OrderedList m="0px" gap="0px">
                <Flex
                  align="start"
                  gap={0}
                  direction={{ base: "row", md: "column" }}
                  wrap={{ base: "wrap", md: "nowrap" }}
                  px="0px"
                >
                  {TermsData.map((data, i) => (
                    <Tab
                      fontWeight="500"
                      fontSize={{ base: "12px", md: "14px" }}
                      letterSpacing="-0.14px"
                      lineHeight="20px"
                      _selected={{ fontWeight: "bold" }}
                      style={{ whiteSpace: "nowrap" }}
                      key={i}
                    >
                      {i + 1}. {data.title}
                    </Tab>
                  ))}
                </Flex>
              </OrderedList>
            </TabList>
          </Flex>
          <VStack w="full" align="start" wrap="wrap">
            <TabPanels>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    1. Acceptance of Terms
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    By creating an account and using PetVet.AI, you agree that
                    you have read, understood, and accepted these Terms of
                    Service and the Privacy Policy. If you disagree with any
                    part of these Terms, you must discontinue your use of the
                    Services.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    2. Account Creation and Security
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    To use our Services, you may sign up using a Google or
                    Facebook account, or by creating an account with your email
                    address and a password. You agree to provide accurate,
                    current, and complete information about yourself. You also
                    agree to maintain the security of your password and
                    identification, and be fully responsible for all use of your
                    account and for any actions that take place using your
                    account.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    3. User Responsibilities
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    As a user, you are responsible for your own actions and the
                    consequences of your usage of the Services. You agree not to
                    misuse the Services and to conduct yourself in a respectful
                    manner. Any inappropriate use of the Services may lead to
                    termination of your account.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    4. Pet Profile
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    Users can create a profile for their pet(s), including
                    information about their pet’s name, age, breed, and a
                    picture. By creating a pet profile, you confirm that you are
                    the owner of the pet, or have obtained necessary permissions
                    from the pet’s owner to provide us with such information.
                    Please do not share sensitive information about your pet
                    that you would not want to be publicly available.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    5. Use of AI Chat
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    Users may start a chat with our AI, ChatGPT, regarding any
                    symptoms observed in their pets. Please note that this chat
                    is not a substitute for professional veterinary advice,
                    diagnosis, or treatment. For any serious or urgent health
                    concerns, we advise you to seek professional veterinary care
                    immediately.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    6. Intellectual Property
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    The Services and all of their original content (including
                    the AI chat responses), features, and functionality are and
                    will remain the exclusive property of PetVet.AI. The
                    Services are protected by copyright, trademark, and other
                    laws. Our trademarks and trade dress may not be used in
                    connection with any product or service without our prior
                    written consent.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    7. Changes to Terms
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    We reserve the right, at our discretion, to modify these
                    Terms at any time. If we make changes to these Terms, we
                    will provide you with notice of those changes, such as by
                    updating the "Last updated" date at the top of these Terms
                    or by posting a notice on our App. By continuing to access
                    or use our Services after we have posted a modification, you
                    are indicating that you agree to be bound by the modified
                    Terms.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    8. Contact
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    If you have any questions about these Terms, please contact
                    us at
                    <a onClick={redirectToEmail} className="mailstyle">
                      support@petvet.ai
                    </a>
                    .
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    9. Governing Law
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    These Terms and any separate agreements whereby we provide
                    you Services shall be governed by and construed in
                    accordance with the laws of the jurisdiction where our
                    company is located.
                  </Text>
                </VStack>
              </TabPanel>
            </TabPanels>
          </VStack>
        </Flex>
      </Tabs>
    </>
  );
};

export default TermsOfServices;
