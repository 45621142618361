import {
  Flex,
  HStack,
  ListItem,
  OrderedList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import "./privacy.css";
const PrivacyPage = () => {
  const redirectToEmail = () => {
    window.location.href = "mailto:email-privacy@petvet.ai";
  };
  return (
    <>
      <Tabs w="full" variant="unstyled">
        <Flex
          direction={{ base: "column", md: "row" }}
          width="full"
          minH="85vh"
          align="start"
          border="1px solid #E5E4F9"
          borderRadius="22px"
        >
          <VStack
            align="start"
            width={{ base: "auto", md: "260px" }}
            bg="#F1F0F9"
            borderRadius="22px"
            minH={{ base: "auto", md: "85vh" }}
            padding="20px 15px"
          >
            <Text
              fontSize={{ base: "14px", md: "24px" }}
              fontWeight="500"
              color="#737098"
            >
              Privacy
            </Text>
            <TabList borderBottom="none">
              <OrderedList m="0px" gap="0px">
                <Flex
                  align="start"
                  gap={0}
                  direction={{ base: "row", md: "column" }}
                  wrap={{ base: "wrap", md: "nowrap" }}
                >
                  <Tab
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    letterSpacing="-0.14px"
                    lineHeight="20px"
                    _selected={{ fontWeight: "bold" }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    1. Introduction
                  </Tab>
                  <Tab
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    letterSpacing="-0.14px"
                    lineHeight="20px"
                    _selected={{ fontWeight: "bold" }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    2. What Information We Collect
                  </Tab>
                  <Tab
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    letterSpacing="-0.14px"
                    lineHeight="20px"
                    _selected={{ fontWeight: "bold" }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    3. How We Use Your Information
                  </Tab>
                  <Tab
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    letterSpacing="-0.14px"
                    lineHeight="20px"
                    _selected={{ fontWeight: "bold" }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    4. How We Share Your Information
                  </Tab>
                  <Tab
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    letterSpacing="-0.14px"
                    lineHeight="20px"
                    _selected={{ fontWeight: "bold" }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    5. Your Rights Under CCPA and GDPR
                  </Tab>
                  <Tab
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    letterSpacing="-0.14px"
                    lineHeight="20px"
                    _selected={{ fontWeight: "bold" }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    6. How We Protect Your Information
                  </Tab>
                  <Tab
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    letterSpacing="-0.14px"
                    lineHeight="20px"
                    _selected={{ fontWeight: "bold" }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    7. Changes to This Privacy Policy
                  </Tab>
                  <Tab
                    fontWeight="500"
                    fontSize={{ base: "12px", md: "14px" }}
                    letterSpacing="-0.14px"
                    lineHeight="20px"
                    _selected={{ fontWeight: "bold" }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    8. Contact Us
                  </Tab>
                </Flex>
              </OrderedList>
            </TabList>
          </VStack>
          <VStack w="full" align="start" wrap="wrap">
            <TabPanels>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    1. Introduction
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    At PetVet.Ai, we are committed to protecting your privacy
                    and ensuring the highest level of security for your personal
                    data. This Privacy Policy outlines our practices regarding
                    the collection, use, and safeguarding of your data in
                    compliance with the California Consumer Privacy Act (CCPA)
                    and the General Data Protection Regulation (GDPR).
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    2. What Information We Collect
                  </Text>
                  <Text fontSize="16px" fontWeight="500">
                    User Information
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    When you create an account with PetVet.Ai, we collect the
                    following information:
                  </Text>
                  <UnorderedList>
                    <ListItem>Your name</ListItem>
                    <ListItem>Your email address</ListItem>
                    <ListItem>Your phone number</ListItem>
                    <ListItem>Your password</ListItem>
                  </UnorderedList>
                  <Text fontSize="14px" fontWeight="500">
                    You can choose to create an account using your Google or
                    Facebook account. In such cases, we will receive your
                    profile information from these platforms.
                  </Text>
                </VStack>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="16px" fontWeight="500">
                    Pet Information
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    We also collect information about your pet(s) to provide
                    personalized veterinary advice. This includes:
                  </Text>
                  <UnorderedList>
                    <ListItem>Your pet's name</ListItem>
                    <ListItem>Your pet's age</ListItem>
                    <ListItem>Your pet's breed</ListItem>
                    <ListItem>A picture of your pet</ListItem>
                  </UnorderedList>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    3. How We Use Your Information
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    We use your personal data to
                  </Text>
                  <UnorderedList>
                    <ListItem>Create and manage your account</ListItem>
                    <ListItem>Provide personalized veterinary advice</ListItem>
                    <ListItem>Improve our services</ListItem>
                    <ListItem>Respond to your inquiries</ListItem>
                  </UnorderedList>
                  <Text fontSize="14px" fontWeight="500">
                    We use your pet's data to:
                  </Text>
                  <UnorderedList>
                    <ListItem>Provide personalized veterinary advice</ListItem>
                    <ListItem>
                      Improve our understanding of different pet breeds and
                      their health needs
                    </ListItem>
                  </UnorderedList>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    4. How We Share Your Information
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    We do not sell your personal data to third parties. We may
                    share your data with service providers who assist us in
                    providing our services, such as hosting providers or
                    customer service platforms. These providers are
                    contractually obligated to keep your data secure and use it
                    only for the purposes we specify.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    5. Your Rights Under CCPA and GDPR
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    Under the CCPA and GDPR, you have certain rights regarding
                    your personal data. These include:
                  </Text>
                  <UnorderedList>
                    <ListItem>
                      The right to know what personal data we collect, use,
                      disclose, and sell
                    </ListItem>
                    <ListItem>
                      The right to delete personal data we have collected or
                      maintained
                    </ListItem>
                    <ListItem>
                      The right to opt-out of the sale of your personal data
                    </ListItem>
                    <ListItem>
                      The right to non-discrimination for exercising your
                      privacy rights
                    </ListItem>
                  </UnorderedList>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    6. How We Protect Your Information
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    We implement a variety of security measures to maintain the
                    safety of your personal information. Your personal
                    information is contained behind secured networks and is only
                    accessible by a limited number of persons who have special
                    access rights to such systems, and are required to keep the
                    information confidential.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    7. Changes to This Privacy Policy
                  </Text>

                  <Text fontSize="14px" fontWeight="500">
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack w="full" align="start" gap="16px" pt="15px">
                  <Text fontSize="18px" fontWeight="500">
                    8. Contact Us
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    If you have any questions or suggestions about our Privacy
                    Policy, do not hesitate to contact us at
                    <a onClick={redirectToEmail} className="mailstyle">
                      email-privacy@petvet.ai
                    </a>
                  </Text>
                  <Text fontSize="14px" fontWeight="500">
                    Last updated: July 20, 2023
                  </Text>
                </VStack>
              </TabPanel>
            </TabPanels>
          </VStack>
        </Flex>
      </Tabs>
    </>
  );
};

export default PrivacyPage;
