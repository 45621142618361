import { Button, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { lightning } from "../../../partial/images";
import "./proplan.css";
const UpgradeStep2 = ({ setStepCount }: any) => {
  const details = [
    "Unlimited Pets",
    "Unlimited Requests",
    "Future Features Included",
  ];
  return (
    <>
      <Flex justify="center" w="full">
        <VStack
          align="center"
          gap="44px"
          padding="44px"
          borderRadius="40px"
          border="1px solid #dadada"
        >
          <VStack gap="32px" width="327px">
            <VStack gap="24px" width="327px">
              <Image src={lightning} height="96px" width="98px" />
              <Text
                fontSize="32px"
                fontWeight="700"
                color="#000"
                lineHeight="42px"
                letterSpacing="-0.32px"
                textAlign="center"
              >
                Upgrade to Premium. Get unlimited Requests.
              </Text>
              <VStack align="start">
                {details.map((data, i) => (
                  <HStack color="#2FAF62" key={i} justify="start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.5 17.5C6.3575 17.5 3 14.1425 3 10C3 5.8575 6.3575 2.5 10.5 2.5C14.6425 2.5 18 5.8575 18 10C18 14.1425 14.6425 17.5 10.5 17.5Z"
                        fill="#2FAF62"
                      />
                      <path
                        d="M13.8334 8.33301L9.66675 12.4997L7.16675 9.99967"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Text
                      fontSize="16px"
                      fontWeight="500"
                      lineHeight="20px"
                      letterSpacing="-0.16px"
                    >
                      {data}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            </VStack>
            <VStack>
              <Button
                className="btn"
                bg="transparent"
                border="1px solid #E5E4F9"
                color="#272655"
                onClick={() => setStepCount(2)}
              >
                $4.99 / month
              </Button>

              <Button
                className="btn"
                bg="#8F4BF6"
                color="#FFFFFF"
                onClick={() => setStepCount(2)}
              >
                $24.99 / year
              </Button>
            </VStack>
          </VStack>
          <Text className="plan-des" w="600px">
            By subscribing to The Pet Vet, you agree to automatic renewal of
            your subscription until cancelled. Read the Terms and Conditions of
            our cancellation policy carefully, as fuilure to cancel before the
            end of the billing period will result in continued billing.
          </Text>
        </VStack>
      </Flex>
    </>
  );
};

export default UpgradeStep2;
