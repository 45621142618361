import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { peticon1 } from "../../../partial/images";
import { petStore, usePetBreedStore } from "../../../store";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import "../addPet.css";
import { BiCircle } from "react-icons/bi";
import { useFetch } from "../../../interceptors";
const SelectPet = () => {
  const { petValue, setPetValue } = usePetBreedStore();
  const { incrementStepNumber } = petStore();
  const [showSelect, setShowSelect] = useState(false);
  const toast = useToast();
  const [petsOptions, setPetOptions] = useState<any>([
    "Dog",
    "Cat",
    "Bird",
    "Fish",
    "Rodent",
    "Reptile",
    "Amphibian",
    "Horse",
    "Chicken",
    "Duck",
    "Goat",
    "Sheep",
    "Pig",
  ]);
  useEffect(() => {
    try {
      useFetch
        .get(`/petTypes`)
        .then((response) => {
          setPetOptions(response.data.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const handleSubmit = () => {
    petValue
      ? incrementStepNumber()
      : toast({
          position: "top",
          title: `Please Select Pet Type`,
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
  };
  return (
    <>
      <Flex height="auto" width={{ base: "auto", md: "608px" }}>
        <VStack
          align="center"
          padding={{ base: "10px 18px", md: "72px 132px" }}
          gap="24px"
          borderRadius="48px"
          border="1px solid #dadada"
        >
          <Text fontWeight="700" fontSize="32px">
            Select Your Pet Type
          </Text>
          <Text
            fontWeight="400"
            fontSize="16px"
            textAlign="center"
            color="#737098"
          >
            This info helps PetVet be as specific as possible.
          </Text>
          <Image src={peticon1} borderRadius="20px" />
          <VStack position="relative">
            <HStack
              justify="space-between"
              bg="white"
              border="1px solid #F1F0F9"
              color="#737098"
              padding="24px"
              borderRadius="24px"
              height="74px"
              width={{ base: "full", md: "344px" }}
              cursor="pointer"
              onClick={() => {
                setShowSelect((prevShowSelect) => !prevShowSelect);
              }}
            >
              {petValue ? (
                <Text>{petValue}</Text>
              ) : (
                <Text>Select From the list</Text>
              )}
              <Icon as={MdOutlineKeyboardArrowDown} />
            </HStack>
            {showSelect && (
              <VStack
                height="300px"
                overflowY="scroll"
                position="absolute"
                top="75px"
                zIndex="50"
                bg="white"
                border="1px solid #F1F0F9"
                borderRadius="20px"
                width={{ base: "300px", md: "full" }}
                padding="24px 16px"
              >
                {petsOptions.map((pets: any) => (
                  <Flex
                    key={pets}
                    align="center"
                    justify="start"
                    w={{ base: "270px", md: "310px" }}
                    minH="72px"
                    padding="24px"
                    cursor="pointer"
                    borderWidth="1px"
                    borderRadius="24px"
                    boxShadow="sm"
                    fontSize={{ base: "13px", sm: "16px" }}
                    className={petValue === pets ? "radio-checked" : ""}
                    fontWeight="bold"
                    border="1px solid #F1F0F9"
                    _hover={{
                      bg: "transparent",
                      color: "#EC694E",
                      borderColor: "#EC694E",
                      fontWeight: "bold",
                    }}
                    px={5}
                    py={2}
                    whiteSpace={{ base: "break-spaces", md: "nowrap" }}
                    gap="12px"
                    onClick={() => {
                      setPetValue(pets?.petType);
                      setShowSelect(false);
                    }}
                  >
                    {petValue === pets ? (
                      <Icon
                        as={AiOutlineCheckCircle}
                        bg="white"
                        color="#EC694E"
                        fontSize="24px"
                        borderRadius="full"
                      />
                    ) : (
                      <Icon
                        as={BiCircle}
                        color="#E5E4F9"
                        fontSize="24px"
                        borderRadius="full"
                      />
                    )}

                    {pets?.petType}
                  </Flex>
                ))}
              </VStack>
            )}
          </VStack>
          <Button
            bg="#8F4BF6"
            h="60px"
            width={{ base: "80%", md: "344px" }}
            p="18px 32px"
            color="white"
            borderRadius="20px"
            colorScheme="purple"
            onClick={handleSubmit}
          >
            <HStack align="center">
              <Text fontWeight="700" fontSize="16px">
                Continue
              </Text>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M8.293 2.29303C8.48053 2.10556 8.73484 2.00024 9 2.00024C9.26516 2.00024 9.51947 2.10556 9.707 2.29303L14.207 6.79303C14.3945 6.98056 14.4998 7.23487 14.4998 7.50003C14.4998 7.76519 14.3945 8.0195 14.207 8.20703L9.707 12.707C9.5184 12.8892 9.2658 12.99 9.0036 12.9877C8.7414 12.9854 8.49059 12.8803 8.30518 12.6948C8.11977 12.5094 8.0146 12.2586 8.01233 11.9964C8.01005 11.7342 8.11084 11.4816 8.293 11.293L11 8.50003H1.5C1.23478 8.50003 0.98043 8.39467 0.792893 8.20714C0.605357 8.0196 0.5 7.76525 0.5 7.50003C0.5 7.23481 0.605357 6.98046 0.792893 6.79292C0.98043 6.60539 1.23478 6.50003 1.5 6.50003H11L8.293 3.70703C8.10553 3.5195 8.00021 3.26519 8.00021 3.00003C8.00021 2.73487 8.10553 2.48056 8.293 2.29303Z"
                  fill="white"
                />
              </svg>
            </HStack>
          </Button>
        </VStack>
      </Flex>
    </>
  );
};

export default SelectPet;
