import {
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Progress,
  Select,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import uuid from "react-uuid";
import React, { useRef, useState } from "react";
import { imageIcon, noImageDog } from "../../../partial/images";
import { petStore, usePetBreedStore, usePetsStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../../partial/utils/user";
import "../../../interceptors";
import { useFetch } from "../../../interceptors";
import { storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { AiOutlinePlus } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
interface AddImageProps {
  data: string;
  stepNumber: number;
  progressValue: number;
}
const AddPetImage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const {
    petValue,
    setPetValue,
    breed,
    setBreed,
    age,
    setAge,
    petName,
    setPetName,
  } = usePetBreedStore();
  const { stepNumber, incrementStepNumber, removeStepNumber } = petStore();
  const { updatePetsData } = usePetsStore();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState("");
  const toast = useToast();
  const currentUser = useCurrentUser();

  const handleIconButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleRemoveImage = () => {
    if (imageUrl) {
      setImageUrl("");
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImageLoading(true);
    const file: any = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    const imageId = uuid();
    const imageRef = ref(storage, `images/${currentUser.userId}/${imageId}`);

    try {
      await uploadBytes(imageRef, file).then(async (snapshot) => {
        await getDownloadURL(snapshot.ref).then(async (url: any) => {
          setImageLoading(false);
          setImageUrl(url as string);
        });
      });
    } catch (error) {
      setImageLoading(false);
      console.log("error", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const BODY = {
      _id: currentUser.user._id,
      petType: petValue,
      petAge: age,
      petName: petName,
      breed: breed,
      petImage: imageUrl,
    };
    await useFetch
      .post(`/addPetsInfo`, BODY)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          updatePetsData(res.data?.data);
          toast({
            position: "top",
            title: `Pet Added Successfully`,
            description: "",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          removeStepNumber();
          setPetName("");
          setPetValue("");
          setAge("");
          setBreed("");
          navigate("/home");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast({
          position: "top",
          title: `${error.response.data.message}`,
          description: "",
          status: "error",
          duration: 3000,
          isClosable: false,
        });
      });
  };

  return (
    <>
      <Flex height="auto" width={{ base: "auto", md: "608px" }}>
        <VStack
          align="center"
          padding={{ base: "10px 18px", md: "72px 132px" }}
          gap="44px"
          borderRadius="48px"
          border="1px solid #dadada"
        >
          <VStack gap="24px" w="full">
            <Text
              fontWeight="700"
              fontSize="32px"
              lineHeight="32px"
              textAlign="center"
            >
              What does your {petValue} look like?
            </Text>
            <Image src={imageIcon} borderRadius="20px" />
            <VStack position="relative">
              <Image
                src={imageUrl || noImageDog}
                height="132px"
                width="132px"
                borderRadius="full"
              />
              {imageLoading && (
                <Spinner
                  position="absolute"
                  top="10"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              )}
              <input
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              {imageUrl ? (
                <Icon
                  onClick={handleRemoveImage}
                  height="36px"
                  width="36px"
                  padding="10px"
                  position="absolute"
                  top="110"
                  borderRadius="full"
                  bg="#EC694E"
                  color="white"
                  as={BsTrash}
                  cursor="pointer"
                />
              ) : (
                <Icon
                  onClick={handleIconButtonClick}
                  height="36px"
                  width="36px"
                  padding="10px"
                  position="absolute"
                  top="110"
                  borderRadius="full"
                  bg="#EC694E"
                  color="white"
                  as={AiOutlinePlus}
                  cursor="pointer"
                />
              )}
            </VStack>
          </VStack>

          <VStack gap="18px" w="full">
            <Button
              bg="white"
              border="1px solid #E5E4F9"
              h="60px"
              w="full"
              p="18px 24px"
              color="#737098"
              borderRadius="20px"
              onClick={handleSubmit}
              isDisabled={imageUrl ? true : false}
              isLoading={isLoading}
            >
              Skip
            </Button>
            <Button
              bg="#8F4BF6"
              h="60px"
              w="full"
              p="18px 32px"
              color="white"
              borderRadius="20px"
              colorScheme="purple"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              <HStack align="center">
                <Text fontWeight="700" fontSize="16px">
                  Add My Pet
                </Text>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M8.293 2.29303C8.48053 2.10556 8.73484 2.00024 9 2.00024C9.26516 2.00024 9.51947 2.10556 9.707 2.29303L14.207 6.79303C14.3945 6.98056 14.4998 7.23487 14.4998 7.50003C14.4998 7.76519 14.3945 8.0195 14.207 8.20703L9.707 12.707C9.5184 12.8892 9.2658 12.99 9.0036 12.9877C8.7414 12.9854 8.49059 12.8803 8.30518 12.6948C8.11977 12.5094 8.0146 12.2586 8.01233 11.9964C8.01005 11.7342 8.11084 11.4816 8.293 11.293L11 8.50003H1.5C1.23478 8.50003 0.98043 8.39467 0.792893 8.20714C0.605357 8.0196 0.5 7.76525 0.5 7.50003C0.5 7.23481 0.605357 6.98046 0.792893 6.79292C0.98043 6.60539 1.23478 6.50003 1.5 6.50003H11L8.293 3.70703C8.10553 3.5195 8.00021 3.26519 8.00021 3.00003C8.00021 2.73487 8.10553 2.48056 8.293 2.29303Z"
                    fill="white"
                  />
                </svg>
              </HStack>
            </Button>
          </VStack>
        </VStack>
      </Flex>
    </>
  );
};

export default AddPetImage;
