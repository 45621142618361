import { Button, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const UpgradeToPro = () => {
  const navigate = useNavigate();
  return (
    <>
      <VStack justify="center" h="90vh">
        <Text
          fontSize="32px"
          fontWeight="700"
          textAlign="center"
          w="250px"
          color="#000"
        >
          Upgrade Your Account to Pro!
        </Text>
        <Text fontSize="16px" fontWeight="400" textAlign="center" w="290px">
          Lorem ipsum dolor sit amet comsectetur. Haitant lectus ornare sed
          morbi ut temper.
        </Text>
        <Button
          leftIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M11 15H6L13 1V9H18L11 23V15Z" fill="white" />
            </svg>
          }
          borderRadius="20px"
          color="white"
          colorScheme="tomato"
          height="56px"
          bg="#EC694E"
          w="423px"
          onClick={() => navigate("/proPlan")}
        >
          Upgrade to Pro
        </Button>
      </VStack>
    </>
  );
};

export default UpgradeToPro;
