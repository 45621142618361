import {
  Flex,
  HStack,
  Icon,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Inbox from "./inbox";
import Conversation from "./conversation";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { message } from "../../../partial/images";
import { useCurrentUser } from "../../../partial/utils/user";

const ChatWithDoctor = () => {
  const [dimensions, setDimensions] = useState({ width: 375, height: 660 });
  const divRef = useRef<HTMLDivElement>(null);
  const updateDimensions = () => {
    const maxWidth = divRef.current?.offsetWidth || 0;

    const maxHeight = window.innerHeight * 0.73;

    const newWidth = Math.min(dimensions.width, maxWidth);

    const newHeight = Math.min(dimensions.height, maxHeight);

    setDimensions({ width: maxWidth, height: maxHeight });
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const innerHTML = `<div
  class="ja-gadget-virtual-assistant"
>

<script type="text/javascript">

(function () {
  var div = document.querySelector(
    "div[class^='ja-gadget-virtual-assistant']"
  );
  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.href =
    "https://components.justanswer.com/v3/ja-gadget-virtual-assistant.css";
  div.appendChild(link);
  var script = document.createElement("script");
  script.src =
    "https://components.justanswer.com/v3/ja-gadget-virtual-assistant.js";
  script.onload = function () {
    (function () {
      Page(
        (function () {
          var scripts = document.querySelectorAll(
            'script[src="https://components.justanswer.com/v3/ja-gadget-virtual-assistant.js"]'
          );
          return scripts[scripts.length - 1].parentNode;
        })(),
        {
          width: "${dimensions.width}",
          height: "${dimensions.height}",
          partner: "US",
          affiliateId: "impact|2068170|{iradname}",
          profile: "vet",
          settings: "default",
          headerColor: "#ec694e",
          headlineText: "Ask a Veterinarian now",
          headerType: "oneExpert",
          experts: [
            "https://secure.justanswer.com/uploads/VE/vetdeb/Debbie Headshot 500.64x64.jpg",
          ],
          headlineSub1Text:
            "Dr. Janet, DVM UC Davis graduate Interests: Toxicology, Preventive medicine, Behavioral medicine",
          satisfiedCustomers: "21522 Satisfied Customers",
          avatar:
            "https://ww2.justanswer.com/static/affiliates/pearl-avatars/PEARL.png",
          assistantName: "Jane Allen",
          assistantTitle: "Veterinarian's Assistant",
          welcomeMessageText: "Welcome! What's going on with your pet?",
          typingText: "Jane is typing...",
          showCopyright: false,
          expertsOnlineText: "Experts are online now",
          sendButtonText: "Send",
          placeholderText: "Ask your question",
          trackingPixels: {
            transition:
              "https://justanswer.9pctbx.net/c/2068170/1699895/9320",
          },
        }
      ).initialize();
    })();
  };
  div.appendChild(script);
})();
</script>
  <noscript>
  <a
  id="ja-gadget-no-script-url"
  href="https://justanswer.9pctbx.net/c/2068170/1699895/9320?sharedid=PetVetApp&u=https%3A%2F%2Fwww.justanswer.com%2Fsip%2Fvet"
  target="_blank"
  rel="noopener noreferrer"
>
  Ask a question, get an answer ASAP!
</a>
  </noscript>
</div>`;

  const fragment = document.createRange().createContextualFragment(innerHTML);

  useEffect(() => {
    if (divRef && divRef.current) {
      divRef.current.appendChild(fragment);
      let list = document.querySelectorAll("div.ja-gadget-virtual-assistant");
      if (list.length > 1) {
        list.item(1).setAttribute("style", "display:none;");
      }
    }
  }, [divRef.current]);
  return (
    <>
      <Flex
        width="full"
        maxH="89vh"
        justify="center"
        align="flex-start"
        h="full"
      >
        <VStack w="full" align="start">
          <Flex
            w="full"
            bg="#F1F0F9"
            borderRadius="22px"
            padding="10px"
            gap="10px"
            align="center"
          >
            <Flex
              width="265px"
              height="60px"
              borderRadius="20px"
              padding="4px 20px 4px 4px"
              border="1px solid #B1AED3"
              align="center"
            >
              <Image
                mr="4"
                fontSize="16"
                _groupHover={{
                  color: "#fff",
                }}
                height="40px"
                w="40px"
                borderRadius="full"
                src={message}
              />
              <HStack w="75%" justify="space-between" align="center">
                <VStack align="start" gap="1px">
                  <Text color="#272655" fontWeight="700" fontSize="14px">
                    Chat With IRL Vet
                  </Text>
                  <Text color="#272655" fontWeight="400" fontSize="12px">
                    Professional medical advice (paid)
                  </Text>
                </VStack>
              </HStack>
            </Flex>
          </Flex>
          <div
            style={{
              width: "100%",
              maxHeight: "80vh",
              margin: "0px !important",
            }}
            ref={divRef}
          />
        </VStack>
      </Flex>
    </>
  );
};

export default ChatWithDoctor;
