import {
  Button,
  Flex,
  FormControl,
  Icon,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MY_PET_LABEL } from "../../partial/utils/constants";
import { GrEdit } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";
import { noImageDog } from "../../partial/images";
import { useEffect, useState } from "react";

const MyPets = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;
  const [petName, setPetName] = useState();
  const [petAge, setPetAge] = useState();
  const [petType, setPetType] = useState();
  const [petBreed, setPetBreed] = useState();
  useEffect(() => {
    setPetName(data?.petName);
    setPetAge(data?.petAge);
    setPetType(data?.petType);
    setPetBreed(data?.breed);
  }, []);

  return (
    <>
      <VStack gap="20px" mt="30px" marginTop="50px" pt="1rem">
        <Image
          src={data?.petImage ? data?.petImage : noImageDog}
          borderRadius="full"
          height="158px"
          width="158px"
        />
        <VStack width={{ base: "auto", md: "327px" }}>
          <FormControl>
            <Text {...MY_PET_LABEL}>Name</Text>
            <Input
              type="text"
              borderRadius="16px"
              height="56px"
              value={petName}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <Text {...MY_PET_LABEL}>Age</Text>
            <Input
              type="text"
              borderRadius="16px"
              height="56px"
              value={petAge}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <Text {...MY_PET_LABEL}>Pet</Text>
            <Input
              type="text"
              borderRadius="16px"
              height="56px"
              value={petType}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <Text {...MY_PET_LABEL}>Breed</Text>
            <Input
              type="text"
              borderRadius="16px"
              height="56px"
              value={petBreed}
              isDisabled={true}
            />
          </FormControl>
        </VStack>
        <Button
          height="54px"
          width="253px"
          borderRadius="59px"
          border="1px solid #8F4BF6"
          bg="transparent"
          onClick={() => navigate("/editPet", { state: { data: data } })}
        >
          <Flex justify="center" align="center" gap="10px">
            <Icon as={GrEdit} />
            <Text>Edit</Text>
          </Flex>
        </Button>
      </VStack>
    </>
  );
};

export default MyPets;
