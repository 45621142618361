import axios from "axios";
import { useEffect } from "react";
const useFetch = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://d381-182-180-157-33.ngrok-free.app",
});
useFetch.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("userToken");
    const token = user && JSON.parse(user);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
const AxiosInterceptor = ({ children }: any) => {
  useEffect(() => {
    const interceptor = useFetch.interceptors.response.use(
      function (response: any) {
        return response;
      },
      function (error: any) {
        if (error?.response?.status === 498) {
          localStorage.clear();
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );

    return () => useFetch.interceptors.response.eject(interceptor);
  }, []);

  return children;
};

export { useFetch, AxiosInterceptor };
