import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Select,
  Text,
  VStack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "../auth.css";
import { useNavigate } from "react-router-dom";
import { useForm, Resolver } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useFetch } from "../../../interceptors";
import ReactGA from "react-ga4";
import "./signup.css";
import TermModal from "./Modal/termModal";
import PrivacyModal from "./Modal/privacyModal";
type FormValues = {
  phone: string;
  email: string;
  password: string;
};

const FinishRegistration = ({ data }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const privacyDisclosure = useDisclosure();
  const termsDisclosure = useDisclosure();
  const [showPassword, setShowPassword] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const toast = useToast();
  const handleShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };
  const passwordDetails = [
    "At least 8 characters",
    "Both uppercase and lowercase letters (optional)",
    "At least one number or symbol (optional)",
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const emailCheck = (e: any) => {
    const email = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setValidEmail(isValid);
  };

  const onSubmit = handleSubmit((value) => {
    if (value.password.length < 8) {
      setValidPassword(false);
    }
    if (value.password.length > 7) {
      setIsLoading(true);
      useFetch
        .post(`/signup`, {
          email: value.email,
          phoneNumber: phoneNumber,
          password: value.password,
        })
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            toast({
              position: "top",
              title: "User Registered Successfully",
              description: ``,
              status: "success",
              duration: 4000,
              isClosable: true,
            });
            ReactGA.event({
              category: "User",
              action: "sign_up",
              label: "User Signed Up",
            });
            navigate("/login");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response && error.response.status === 409) {
            console.log("Conflict Error:", error.response.data.message);
            toast({
              title: "Error.",
              description: `${error.response.data.message}`,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            console.log("Error:", error);
          }
        });
    }
  });
  const handlePrivacyPolicyClick = () => {
    window.open("/Privacy-Policy", "_blank");
  };
  const handleTermsClick = () => {
    window.open("/terms-services", "_blank");
  };

  return (
    <>
      <VStack flex={1} justify="center" mx="20px">
        <VStack
          align="center"
          border="1px solid #dadada"
          borderRadius="48px"
          height="auto"
          width="auto"
          padding={{ base: "10px 25px", lg: "72px 132px" }}
          gap="24px"
        >
          <VStack w={{ base: "auto", md: "345px" }} gap="24px">
            <VStack>
              <Text className="from-title">Finish Registration</Text>
              <Text className="form-desc">
                Enter the details below to complete your registration
              </Text>
            </VStack>
            <form onSubmit={onSubmit}>
              <VStack gap="16px">
                <FormControl>
                  <FormLabel fontSize="12px" fontWeight="400">
                    Email
                  </FormLabel>
                  <InputGroup bg="transparent" h="56px" borderRadius="16px">
                    <InputLeftElement fontSize="20px" h="56px">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.87078 18.0515C6.79426 18.0515 4.16699 17.5728 4.16699 15.6554C4.16699 13.7381 6.7776 11.9682 9.87078 11.9682C12.9473 11.9682 15.5746 13.721 15.5746 15.6383C15.5746 17.5548 12.964 18.0515 9.87078 18.0515Z"
                          stroke="#737098"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.86507 9.31129C11.884 9.31129 13.5204 7.67492 13.5204 5.65598C13.5204 3.63704 11.884 1.99992 9.86507 1.99992C7.84613 1.99992 6.20901 3.63704 6.20901 5.65598C6.20219 7.66811 7.82719 9.30447 9.83931 9.31129C9.8484 9.31129 9.85673 9.31129 9.86507 9.31129Z"
                          stroke="#737098"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </InputLeftElement>
                    <Input
                      h="56px"
                      borderRadius="16px"
                      type="text"
                      placeholder="name@example.com"
                      value={data?.userEmail}
                      {...register("email")}
                      onChange={emailCheck}
                    />
                    {validEmail && (
                      <InputRightElement h="56px">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.2559 5.24408C17.5814 5.56951 17.5814 6.09715 17.2559 6.42259L8.08926 15.5893C7.76382 15.9147 7.23618 15.9147 6.91074 15.5893L2.74408 11.4226C2.41864 11.0972 2.41864 10.5695 2.74408 10.2441C3.06951 9.91864 3.59715 9.91864 3.92259 10.2441L7.5 13.8215L16.0774 5.24408C16.4028 4.91864 16.9305 4.91864 17.2559 5.24408Z"
                            fill="#22C55E"
                          />
                        </svg>
                      </InputRightElement>
                    )}
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="12px" fontWeight="400">
                    Phone number
                  </FormLabel>
                  <PhoneInput
                    placeholder="(xxx)xxx-xx-xx"
                    className="phoneInput-border"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="12px" fontWeight="400">
                    Password
                  </FormLabel>
                  <InputGroup bg="transparent" h="56px" borderRadius="16px">
                    <InputLeftElement h="56px">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M13.2295 16.2395L13.3799 16.1355C13.8508 15.8099 14.0862 15.6471 14.2968 15.4725C15.4412 14.5241 16.2098 13.202 16.4656 11.7421C16.5127 11.4734 16.5372 11.1892 16.5861 10.6208L16.6114 10.3269C16.6924 9.38597 16.6843 8.43958 16.5873 7.50019L16.5573 7.20949C16.3874 5.56558 15.3782 4.12476 13.887 3.39719C11.4351 2.20094 8.56486 2.20094 6.11297 3.39719C4.62171 4.12476 3.61251 5.56558 3.44271 7.20949L3.41268 7.50019C3.31564 8.43958 3.30757 9.38597 3.38856 10.3269L3.41386 10.6208C3.46279 11.1892 3.48726 11.4734 3.53434 11.7421C3.79016 13.202 4.55878 14.5241 5.70315 15.4725C5.91374 15.6471 6.14923 15.8099 6.62003 16.1355L6.77049 16.2395C7.41059 16.6821 7.73066 16.9035 8.05144 17.0573C9.28272 17.6476 10.7172 17.6476 11.9485 17.0573C12.2693 16.9035 12.5894 16.6821 13.2295 16.2395Z"
                          stroke="#8B8D8F"
                          stroke-width="1.5"
                        />
                        <path
                          d="M7.70831 9.79168L9.37498 11.4583L12.2916 8.33334"
                          stroke="#8B8D8F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </InputLeftElement>

                    <Input
                      h="56px"
                      borderRadius="16px"
                      type={showPassword ? "text" : "password"}
                      placeholder="Your Password "
                      isRequired={true}
                      {...register("password")}
                    />
                    <InputRightElement
                      h="56px"
                      cursor="pointer"
                      onClick={handleShowPassword}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M3.25617 7.20946C6.28135 2.04131 13.7186 2.04131 16.7438 7.20946C17.7521 8.93188 17.7521 11.0681 16.7438 12.7906C13.7187 17.9587 6.28135 17.9587 3.25617 12.7906C2.24794 11.0681 2.24794 8.93188 3.25617 7.20946Z"
                          stroke="#8B8D8F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.9639 10.0506C12.9639 11.7135 11.6364 13.0611 9.99953 13.0611C8.36266 13.0611 7.03609 11.7135 7.03609 10.0506C7.03609 8.38678 8.36266 7.03917 9.99953 7.03917C11.6364 7.03917 12.9639 8.38678 12.9639 10.0506Z"
                          stroke="#8B8D8F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </InputRightElement>
                  </InputGroup>
                  {!validPassword && (
                    <Text
                      textAlign="center"
                      color="#EC694E"
                      fontWeight="700"
                      fontSize="12px"
                    >
                      * Invalid Password
                    </Text>
                  )}
                  <Flex direction="column" mt="8px">
                    {passwordDetails.map((data, i) => (
                      <HStack key={i}>
                        {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.10228 2.604C6.2974 2.4086 6.2974 2.09178 6.10228 1.89637C5.90716 1.70097 5.59082 1.70097 5.3957 1.89637L4.00258 3.29156L2.60461 1.89704C2.4091 1.70202 2.09213 1.70202 1.89663 1.89704C1.70112 2.09206 1.70112 2.40825 1.89663 2.60327L3.296 3.99919L1.90295 5.39432C1.70783 5.58972 1.70783 5.90654 1.90295 6.10195C2.09806 6.29736 2.41441 6.29736 2.60953 6.10195L4.00398 4.70542L5.39646 6.09447C5.59196 6.28949 5.90894 6.28949 6.10444 6.09447C6.29994 5.89945 6.29994 5.58325 6.10444 5.38823L4.71056 3.99779L6.10228 2.604Z"
                          fill="#737098"
                        />
                      </svg> */}
                        <Text className="password-desc">* {data}</Text>
                      </HStack>
                    ))}
                  </Flex>
                </FormControl>
                <Button
                  bg="#8F4BF6"
                  h="auto"
                  w={{ base: "auto", md: "344px" }}
                  p="18px 32px"
                  color="white"
                  borderRadius="20px"
                  colorScheme="purple"
                  type="submit"
                  isLoading={isLoading}
                >
                  Register
                </Button>
              </VStack>
            </form>
            <VStack gap="0">
              <Text>
                * By selection <strong>Register</strong> you agree to our
              </Text>
              <HStack align="start">
                <Text
                  className="finish-terms-link"
                  cursor="pointer"
                  onClick={handleTermsClick}
                >
                  Terms of Service
                </Text>
                <Text fontSize="12px">and</Text>
                <Text
                  className="finish-terms-link"
                  cursor="pointer"
                  onClick={handlePrivacyPolicyClick}
                >
                  Privacy Policy
                </Text>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
        <Flex align="center" gap="4px" pt="40px" mb="10px">
          <Text fontSize="14px" fontWeight="400">
            Already have an account?
          </Text>
          <Text
            fontSize="14px"
            fontWeight="500"
            cursor="pointer"
            onClick={() => navigate("/login")}
          >
            Login
          </Text>
        </Flex>
      </VStack>
      <Modal
        isOpen={termsDisclosure.isOpen}
        onClose={termsDisclosure.onClose}
        size={{ base: "sm", md: "xl", lg: "3xl" }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms of Service</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TermModal />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={privacyDisclosure.isOpen}
        onClose={privacyDisclosure.onClose}
        size={{ base: "sm", md: "xl", lg: "3xl" }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Privacy Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PrivacyModal />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FinishRegistration;
