import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import AddPet from "./pages/addPet";
import HomePage from "./pages/homePage";
import MyPets from "./pages/myPets";
import EditPet from "./pages/myPets/editPet";
import FAQs from "./pages/faqs";
import ChatWithAI from "./pages/chat/chatWithAI";
import ChatWithDoctor from "./pages/chat/chatWithDoctor";
import LogIn from "./pages/auth/logIn";
import SignUp from "./pages/auth/signUp";
import ForgotPassword from "./pages/auth/forgotPassword";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import PrivacyPage from "./pages/privacy";
import TermsOfServices from "./pages/termsofService";
import UpgradeToPro from "./pages/upgradeToPro";
import Steps from "./pages/upgradeToPro/steps";
import PublicTerms from "./pages/termsofService/pubTerms";
import PublicPrivacy from "./pages/privacy/pubPrivacy";
import { useEffect } from "react";
import RxInsuranceCard from "./pages/rxCards/rxInsuranceCard";
import PetInsuranceQuote from "./pages/rxCards/petInsuranceQuote";
function App() {
  return (
    <>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/terms-services" element={<PublicTerms />} />
          <Route path="/privacy-policy" element={<PublicPrivacy />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/addpet" element={<AddPet />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/myPet" element={<MyPets />} />
          <Route path="/editPet" element={<EditPet />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/AiChat" element={<ChatWithAI />} />
          <Route path="/chat" element={<ChatWithDoctor />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPage />} />
          <Route path="/Terms" element={<TermsOfServices />} />
          <Route path="/upgrade" element={<UpgradeToPro />} />
          <Route path="/proPlan" element={<Steps />} />
          <Route path="/rxInsuranceCard" element={<RxInsuranceCard />} />
          <Route path="/petInsuranceQuote" element={<PetInsuranceQuote />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
