import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Progress,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { petStore, usePetBreedStore } from "../../store/index";

import SelectPet from "./step1/SelectPet";
import SelectBreed from "./step2/SelectBreed";
import PetInfo from "./step3/PetInfo";
import AddPetImage from "./step4/AddPetImage";
import { useNavigate } from "react-router-dom";

const AddPet = () => {
  const navigate = useNavigate();
  const { stepNumber, decrementStepNumber, removeStepNumber } = petStore();

  const [progressValue, setProgressValue] = useState(25);
  const {
    petValue,
    setPetValue,
    breed,
    setBreed,
    age,
    setAge,
    petName,
    setPetName,
  } = usePetBreedStore();
  useEffect(() => {
    if (stepNumber === 1) setProgressValue(25);
    else if (stepNumber == 2) setProgressValue(50);
    else if (stepNumber == 3) setProgressValue(75);
    else if (stepNumber == 4) setProgressValue(100);
  }, [stepNumber]);

  return (
    <>
      <VStack justify="center" w="full" gap="24px">
        <Flex
          align="center"
          w={{ base: "full", md: "608px" }}
          justify={stepNumber > 1 ? "space-between" : "end"}
        >
          {stepNumber > 1 && (
            <Icon
              w="48px"
              h="48px"
              padding="12px"
              borderRadius="full"
              border="1px solid #F1F0F9"
              cursor="pointer"
              fontWeight="500"
              onClick={decrementStepNumber}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.4375 18.75L4.6875 12L11.4375 5.25M5.625 12L19.3125 12"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Icon>
          )}
          <Icon
            w="48px"
            h="48px"
            padding="12px"
            borderRadius="full"
            border="1px solid #F1F0F9"
            cursor="pointer"
            fontWeight="500"
            onClick={() => {
              removeStepNumber();
              navigate(-1);
              setPetName("");
              setPetValue("");
              setAge("");
              setBreed("");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16.875 7.125L7.125 16.875M7.125 7.125L16.875 16.875"
                stroke="#272655"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Icon>
        </Flex>
        <VStack w={{ base: "auto", md: "375px" }} gap="16px">
          <HStack
            justify="center"
            height="40px"
            width="120px"
            bg="rgba(47, 175, 98, 0.08)"
            borderRadius="20px"
          >
            <Text color="#2FAF62" fontWeight="700" fontSize="16px">
              STEP {stepNumber} OF 4
            </Text>
          </HStack>
          <Progress
            height="4px"
            borderRadius="5px"
            colorScheme="green"
            max={100}
            value={progressValue}
            w="100%"
          />
        </VStack>
        <Box>
          {stepNumber === 1 && <SelectPet />}
          {stepNumber === 2 && <SelectBreed />}
          {stepNumber === 3 && <PetInfo />}
          {stepNumber === 4 && <AddPetImage />}
        </Box>
      </VStack>
      {/* )} */}
    </>
  );
};

export default AddPet;
