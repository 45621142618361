import { create } from "zustand";
import { useFetch } from "../interceptors";

type AppStore = {
  stepNumber: number;
  incrementStepNumber: () => void;
  decrementStepNumber: () => void;
  removeStepNumber: () => void;
};
interface PetsStore {
  petsData: any[];
  fetchPets: () => Promise<void>;
  updatePetsData: (newData: any[]) => void;
}

export const petStore: any = create<AppStore>((set) => ({
  stepNumber: 1,
  incrementStepNumber: () =>
    set((state: any) => ({ ...state, stepNumber: state.stepNumber + 1 })),
  decrementStepNumber: () =>
    set((state: any) => ({ ...state, stepNumber: state.stepNumber - 1 })),
  removeStepNumber: () => set({ stepNumber: 1 }),
}));

export const usePetsStore = create<PetsStore>((set) => ({
  petsData: [],
  fetchPets: async () => {
    const storedUser = localStorage.getItem("user");
    const currenUser = storedUser ? JSON.parse(storedUser) : null;
    try {
      const response = await useFetch.get(
        `/listOfPets/${currenUser?.user._id}`
      );
      set({ petsData: response.data.data });
    } catch (error) {
      console.log(error);
    }
  },
  updatePetsData: (newData: any) => {
    set({ petsData: newData });
  },
}));
type PetStore = {
  petValue: string;
  setPetValue: (petValue: string) => void;
  breed: string;
  setBreed: (breed: string) => void;
  age: string;
  setAge: (age: string) => void;
  petName: string;
  setPetName: (petName: string) => void;
};

export const usePetBreedStore = create<PetStore>((set) => ({
  petValue: "",
  setPetValue: (petValue) => {
    set({ petValue });
    if (petValue !== "") {
      set({ breed: "" });
    }
  },
  breed: "",
  setBreed: (breed) => set({ breed }),
  age: "",
  setAge: (age) => set({ age }),
  petName: "",
  setPetName: (petName) => set({ petName }),
}));
