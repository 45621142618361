import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { peticon1 } from "../../../partial/images";
import { petStore, usePetBreedStore } from "../../../store";
import { useFetch } from "../../../interceptors";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const SelectBreed = () => {
  const inputref = useRef<HTMLInputElement>(null);
  const { petValue, breed, setBreed } = usePetBreedStore();
  const { incrementStepNumber } = petStore();
  const [searchResult, setSearchResult] = useState<any>([]);
  const [showResult, setShowResult] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const handleFocusBlur = (value: boolean) => {
    setIsFocused(value);
  };

  const handleSubmit = () => {
    if (breed) {
      incrementStepNumber();
    }
  };
  useEffect(() => {
    if (isFocused) {
      if (breed.length > 0) {
        useFetch
          .get(`sreachBreed/${petValue}/${breed}`)
          .then((response) => {
            setSearchResult(response.data.partialMatches);
            setShowResult(true);
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    }
  }, [breed, showAll]);

  const handleBreed = (search: any) => {
    setBreed(search);
    setShowResult(false);
  };
  const handleShowAll = () => {
    setShowResult((prevShowResult) => !prevShowResult);
    setShowAll((prevShowAll) => !prevShowAll);
    if (!showAll) {
      useFetch
        .get(`sreachBreed/${petValue}/All`)
        .then((response) => {
          setSearchResult(response.data.partialMatches);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  return (
    <>
      <Flex height="auto" width={{ base: "auto", md: "608px" }}>
        <form onSubmit={handleSubmit}>
          <VStack
            align="center"
            padding={{ base: "10px 18px", md: "72px 132px" }}
            gap="24px"
            borderRadius="48px"
            border="1px solid #dadada"
          >
            <VStack w="full" gap="8px">
              <Text
                fontWeight="700"
                fontSize="32px"
                textAlign="center"
                lineHeight="32px"
                letterSpacing="-0.32px"
              >
                What Breed of {petValue}?
              </Text>
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="20px"
                textAlign="center"
                color="#737098"
                letterSpacing="-0.16px"
              >
                Select your breed: If unknown, select ‘Mixed Breed/Shelter Pet”.
                If mixed, select whatever breed is the most present.
              </Text>
            </VStack>
            <Image src={peticon1} borderRadius="20px" />
            <VStack w="full" position="relative">
              <HStack
                justify="space-between"
                bg="white"
                border="1px solid #F1F0F9"
                color="#737098"
                padding="20px"
                borderRadius="20px"
                height="56px"
                width={{ base: "full", md: "344px" }}
              >
                <Input
                  type="text"
                  placeholder="Select breed"
                  variant="flushed"
                  ref={inputref}
                  onFocus={() => handleFocusBlur(true)}
                  onBlur={() => handleFocusBlur(false)}
                  value={breed}
                  onChange={(e) => {
                    breed && setShowAll(false);

                    setBreed(e.target.value);
                  }}
                  isRequired={true}
                  border="none"
                />

                <Icon
                  as={MdOutlineKeyboardArrowDown}
                  cursor="pointer"
                  onClick={handleShowAll}
                />
              </HStack>
              {showResult && (
                <VStack
                  width={{ base: "80%", md: "344px" }}
                  position="absolute"
                  top="50px"
                  zIndex="99"
                  height="auto"
                  p="15px 20px"
                  align="start"
                  boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04);"
                  bg="white"
                  borderRadius="0px 0px 20px 20px"
                  gap="5"
                  maxH="250px"
                  overflowY="scroll"
                >
                  {searchResult &&
                    searchResult.map((data: any) => (
                      <Flex
                        key={data}
                        onClick={() => handleBreed(data?.name)}
                        cursor="pointer"
                      >
                        <Text
                          fontWeight="400"
                          fontSize="16px"
                          lineHeight="20px"
                          color="#737098"
                          letterSpacing="-0.16px"
                        >
                          {data?.name}
                        </Text>
                      </Flex>
                    ))}
                </VStack>
              )}
            </VStack>
            <Button
              bg="#8F4BF6"
              h="60px"
              width={{ base: "80%", md: "344px" }}
              p="18px 32px"
              color="white"
              borderRadius="20px"
              colorScheme="purple"
              type="submit"
            >
              <HStack align="center">
                <Text fontWeight="700" fontSize="16px">
                  Continue
                </Text>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M8.293 2.29303C8.48053 2.10556 8.73484 2.00024 9 2.00024C9.26516 2.00024 9.51947 2.10556 9.707 2.29303L14.207 6.79303C14.3945 6.98056 14.4998 7.23487 14.4998 7.50003C14.4998 7.76519 14.3945 8.0195 14.207 8.20703L9.707 12.707C9.5184 12.8892 9.2658 12.99 9.0036 12.9877C8.7414 12.9854 8.49059 12.8803 8.30518 12.6948C8.11977 12.5094 8.0146 12.2586 8.01233 11.9964C8.01005 11.7342 8.11084 11.4816 8.293 11.293L11 8.50003H1.5C1.23478 8.50003 0.98043 8.39467 0.792893 8.20714C0.605357 8.0196 0.5 7.76525 0.5 7.50003C0.5 7.23481 0.605357 6.98046 0.792893 6.79292C0.98043 6.60539 1.23478 6.50003 1.5 6.50003H11L8.293 3.70703C8.10553 3.5195 8.00021 3.26519 8.00021 3.00003C8.00021 2.73487 8.10553 2.48056 8.293 2.29303Z"
                    fill="white"
                  />
                </svg>
              </HStack>
            </Button>
          </VStack>
        </form>
      </Flex>
    </>
  );
};

export default SelectBreed;
