import { useEffect, useState } from "react";
import { Flex, HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import Inbox from "./inbox";
import Conversation from "./conversation";
import { hitChatGpt } from "../../../openAI/openAi";
import { chat, noImageDog } from "../../../partial/images";
import { useFetch } from "../../../interceptors";
import { usePetsStore } from "../../../store";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const ChatWithAI = () => {
  const [messages, setMessages] = useState<any>([]);
  const [finalMessages, setFinalMessages] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPets, setShowPets] = useState(false);
  const [petValue, setPetValue] = useState("");
  const [promptForDog, setpromptForDog] = useState<any>();
  const [promptForOthers, setpromptForOthers] = useState<any>();
  const { fetchPets, petsData } = usePetsStore();

  useEffect(() => {
    fetchPets();
  }, []);
  const [inputMessage, setInputMessage] = useState("");
  useEffect(() => {
    useFetch
      .get(`/allprompt`)
      .then((res) => {
        const dogprompt = res?.data?.data[0]?.promptForDog;
        setpromptForDog(dogprompt);
        const othersPrompt = res?.data?.data[0]?.promptForOthers;
        setpromptForOthers(othersPrompt);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);

  useEffect(() => {
    if (selectedUser?._id) {
      const dogFinalPrompt = eval("`" + promptForDog + "`");
      const OthersFinalPrompt = eval("`" + promptForOthers + "`");
      handleSendMessage(
        selectedUser.petType === "Dog" ? dogFinalPrompt : OthersFinalPrompt,
        "fresh"
      );
    }
  }, [selectedUser?._id]);
  const handleSendMessage = (newMessage: any, type = "old") => {
    if (!newMessage.trim().length) {
      return;
    }
    if (type !== "old") {
      setIsLoading(true);
      useFetch
        .get(`/chatHistory/${selectedUser?._id}`)
        .then((response) => {
          setIsLoading(false);
          setFinalMessages(response.data.chatHistory.message);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("ERROR", error);
        });
    }
    const data = newMessage;
    setInputMessage("");
    const currentTimeStamp = new Date().toISOString();
    // if (finalMessages.length < 1) {
    //   setIsLoading(true);
    // }
    let callMsgs: any =
      type === "old"
        ? [...messages, { role: "user", content: data }]
        : [{ role: "user", content: data }];
    setMessages([...callMsgs, { role: "typing", content: "" }]);
    let newPostMessages: any =
      type === "old"
        ? [{ role: "user", content: data, time: currentTimeStamp }]
        : [];
    let newFinalMessages: any =
      type === "old"
        ? [
            ...finalMessages,
            { role: "user", content: data, time: currentTimeStamp },
          ]
        : [];

    setFinalMessages([...newFinalMessages, { role: "typing", content: "" }]);

    setTimeout(async () => {
      try {
        let response = await hitChatGpt(callMsgs);
        response.json().then((data: any) => {
          const choices = data.choices;
          let responses: any = [];
          choices.forEach((choice: any) => {
            responses.push({
              role: "assistant",
              content: choice.message.content,
            });
            responses.forEach((response: any) => {
              newFinalMessages.push({
                role: "assistant",
                content: response.content,
                time: currentTimeStamp,
              });
            });
            responses.forEach((response: any) => {
              newPostMessages.push({
                role: "assistant",
                content: response.content,
                time: currentTimeStamp,
              });
            });
          });

          setMessages((old: any) => [
            ...old.filter((element: any) => element.role !== "typing"),
            ...responses,
          ]);

          try {
            useFetch
              .post(`/aiChat`, {
                pet_Id: selectedUser?._id,
                messages: newPostMessages,
              })
              .then((res) => {
                const resMessage = res.data?.data?.message;

                setFinalMessages(() => [...resMessage]);
              })
              .catch((error) => {
                console.log(error);
              });
            newPostMessages = [];
          } catch (error) {
            console.error("Error occurred while Storing the Messages", error);
          }
        });
      } catch (error) {
        setIsLoading(false);
        console.error(
          "Error occurred while processing the API response:",
          error
        );
      }
    }, 0);
  };

  return (
    <>
      <Flex
        width="full"
        // maxH="89vh"
        justify="center"
        align="flex-start"
        h="full"
      >
        <HStack
          width="full"
          // minH="85vh"
          maxH="85vh"
          align="start"
          border="1px solid #E5E4F9"
          borderRadius="22px"
          gap="1px"
          display={{ base: "none", md: "none", lg: "flex" }}
        >
          <Inbox setSelectedUser={setSelectedUser} />
          <Conversation
            isLoading={isLoading}
            inputMessage={inputMessage}
            messages={finalMessages}
            handleSendMessage={handleSendMessage}
            setInputMessage={setInputMessage}
            selectedUser={selectedUser}
          />
        </HStack>
        <VStack w="full" align="start" display={{ base: "flex", lg: "none" }}>
          <Flex
            w="full"
            bg="#F1F0F9"
            borderRadius="22px"
            padding="20px 10px"
            gap="10px"
            align="center"
            wrap={{ base: "wrap", md: "nowrap" }}
          >
            <Image
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "#fff",
              }}
              src={chat}
              display={{ base: "flex", md: "none" }}
            />
            <Flex
              width="233px"
              height="60px"
              borderRadius="20px"
              padding="10px"
              border="1px solid #B1AED3"
              display={{ base: "none", md: "flex", lg: "none" }}
            >
              <Image
                mr="4"
                fontSize="16"
                _groupHover={{
                  color: "#fff",
                }}
                src={chat}
              />
              <HStack w="75%" justify="space-between" align="center">
                <VStack align="start" gap="1px">
                  <Text color="#272655" fontWeight="700" fontSize="16px">
                    Chat With AI Vet
                  </Text>
                  <Text color="#272655" fontWeight="400" fontSize="14px">
                    Get helpful advice (free)
                  </Text>
                </VStack>
              </HStack>
            </Flex>
            <VStack w={{ base: "200px", md: "245px" }} position="relative">
              <HStack
                justify="space-between"
                bg="white"
                border="1px solid #F1F0F9"
                color="#737098"
                padding="20px"
                borderRadius="20px"
                w="full"
                height="60px"
                cursor="pointer"
                onClick={() => setShowPets((prevShowPets) => !prevShowPets)}
              >
                {selectedUser.petName ? (
                  <HStack
                    cursor="pointer"
                    height="52px"
                    width="182px"
                    borderRadius="59px"
                    gap="6px"
                    padding="6px 16px 6px 6px"
                  >
                    <Image
                      src={
                        selectedUser.petImage
                          ? selectedUser.petImage
                          : noImageDog
                      }
                      height="40px"
                      width="40px"
                    />
                    <Text fontSize="14px" fontWeight="500">
                      {selectedUser?.petName ? selectedUser?.petName : ""}
                    </Text>
                  </HStack>
                ) : (
                  <Text>Select Pet</Text>
                )}
                <Icon as={MdOutlineKeyboardArrowDown} />
              </HStack>
              {showPets && (
                <VStack
                  // width={{ base: "80%", md: "344px" }}
                  w={{ base: "200px", md: "full" }}
                  position="absolute"
                  top="70px"
                  zIndex="99"
                  height="auto"
                  p={{ base: "5px 8px", md: "10px 12px" }}
                  boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04);"
                  bg="white"
                  borderRadius="20px"
                  gap="8px"
                  maxH="250px"
                  overflowY="auto"
                >
                  {petsData.map((pet: any) => (
                    <HStack
                      height="56px"
                      w={{ base: "175px", md: "213px" }}
                      p="6px 16px 6px 6px"
                      whiteSpace={{ base: "break-spaces", md: "nowrap" }}
                      gap="8px"
                      color="#B1AED3"
                      key={pet?._id}
                      padding="6px 16px 6px 6px"
                      onClick={() => {
                        setPetValue(pet?._id);
                        setSelectedUser(pet);
                        setShowPets(false);
                      }}
                      fontWeight="bold"
                      border="1px solid #F1F0F9"
                      _hover={{
                        bg: "transparent",
                        color: "#EC694E",
                        borderColor: "#EC694E",
                        fontWeight: "bold",
                      }}
                      cursor="pointer"
                      borderWidth="1px"
                      borderRadius="24px"
                      boxShadow="sm"
                      fontSize={{ base: "13px", sm: "16px" }}
                      className={petValue === pet?._id ? "radio-checked" : ""}
                    >
                      <Image
                        src={pet?.petImage || noImageDog}
                        borderRadius="full"
                        height="40px"
                        width="40px"
                      />
                      <Text fontSize="14px" fontWeight="500">
                        {pet.petName}
                      </Text>
                    </HStack>
                  ))}
                </VStack>
              )}
            </VStack>
          </Flex>
          <Conversation
            isLoading={isLoading}
            inputMessage={inputMessage}
            handleSendMessage={handleSendMessage}
            setInputMessage={setInputMessage}
            messages={finalMessages}
            selectedUser={selectedUser}
          />
        </VStack>
      </Flex>
    </>
  );
};

export default ChatWithAI;
