import { Button, Text, VStack } from "@chakra-ui/react";
import LandingPage from "../landing-page/LandingPage";

const HomePage = () => {
  return (
    <>
      <LandingPage />
      {/* <VStack
        justify="center"
        w={{ base: "80%", md: "50%" }}
        minH="70vh"
        gap="30px"
      >
        <VStack>
          <Text
            textAlign="center"
            fontSize="24px"
            fontWeight="700"
            color="#000"
          >
            Welcome to Pet Vet AI!
          </Text>
          <Text
            textAlign="center"
            fontSize="16px"
            fontWeight="400"
            color="#000"
          >
            Your pet's well-being matters. Discover accurate, AI-powered
            insights on symptoms, conditions, and treatments – all for free.
            Connect with vets through 'Chat with IRL Vet' (paid). Thank you for
            choosing Pet Vet AI!
          </Text>
        </VStack>
        <HStack display={{ base: "flex", lg: "none" }}>
          <Button
            onClick={() => navigate("/AiChat")}
            bg="#8F4BF6"
            color="white"
            colorScheme="purple"
            padding={{ base: "10px", md: "20px" }}
            borderRadius="14px"
          >
            Chat With AI Vet
          </Button>
          <Button
            onClick={() => navigate("/chat")}
            bg="#EC694E"
            color="white"
            colorScheme="orange"
            padding={{ base: "10px", md: "20px" }}
            borderRadius="14px"
          >
            Chat With IRL Vet
          </Button>
        </HStack>
      </VStack> */}
      <VStack display="none" justify="center" h="80vh">
        <Text
          fontSize="32px"
          fontWeight="700"
          textAlign="center"
          w="250px"
          color="#000"
        >
          Upgrade Your Account to Pro!
        </Text>
        <Text fontSize="16px" fontWeight="400" textAlign="center" w="290px">
          Lorem ipsum dolor sit amet comsectetur. Haitant lectus ornare sed
          morbi ut temper.
        </Text>
        <Button
          leftIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M11 15H6L13 1V9H18L11 23V15Z" fill="white" />
            </svg>
          }
          borderRadius="20px"
          color="white"
          colorScheme="tomato"
          height="56px"
          bg="#EC694E"
          w="423px"
        >
          Upgrade to Pro
        </Button>
      </VStack>
    </>
  );
};

export default HomePage;
