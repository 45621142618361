import { Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
const Footer = () => {
  return (
    <Flex w="full" padding="8px 24px" align="center" justify="space-between">
      <Text color="#737098" fontSize="12px" fontWeight="500">
        © 2023 PetVet
      </Text>
      <HStack>
        <Link
          to="https://blog.petvet.ai"
          target="_blank"
          rel="noopener noreferrer"
          className="link-class"
        >
          Blog
        </Link>
        <Text color="#737098" fontSize="12px" fontWeight="500">
          •
        </Text>
        <Link to="/PrivacyPolicy" className="link-class">
          Privacy
        </Link>
        <Text color="#737098" fontSize="12px" fontWeight="500">
          •
        </Text>
        <Link to="/Terms" className="link-class">
          Terms of Service
        </Link>
      </HStack>
    </Flex>
  );
};

export default Footer;
