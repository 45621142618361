export const LINK_FLEX = {
  align: "center",
  padding: "4",
  mx: "3",
  borderRadius: "lg",
  role: "group",
  cursor: "pointer",
  _hover: {
    borderRadius: "56px",
    background: "gray.300",
    color: "#fff",
  },
};
export const RX_CARD = {
  // background: "#FD521B",
  background: "#EC694E",
  color: "#FFFFFF",
  display: "flex",
  width: "254px",
  height: "aauto",
  padding: "8px 12px",
  justify: "center",
  align: "center",
  borderRadius: "43px",
  border: "1px solid #FD521B",
  _hover: {
    background: "#FD521B",
  },
};
export const HEADER_BUTTON = {
  align: "center",
  color: "#272655",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
};
export const MY_PET_LABEL = {
  padding: "0px 0px 10px 5px",
  color: "#737098",
  fontFamily: "Barlow",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
};
export const TAB_STYLE = {
  padding: "0",
  fontWeight: "500",
  fontSize: "14px",
  letterSpacing: "-0.14px",
  lineHeight: "20px",
  gap: "0",
};
