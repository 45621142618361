import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from "react-ga4";

import "@fontsource/barlow";
import "./index.css";
import { AxiosInterceptor } from "./interceptors";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const theme = extendTheme({
  fonts: {
    body: "Barlow",
    heading: "Barlow",
    text: `Barlow`,
  },
});
const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
if (googleAnalyticsId) {
  ReactGA.initialize(googleAnalyticsId);
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "PetVet.ai - The World's 1st Artificial Intelligence Veterinarian",
  });
} else {
  console.error("Google Analytics tracking ID is not defined.");
}
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <GoogleOAuthProvider
          clientId={
            process.env.REACT_APP_GOOGLE_CLIENT_ID
              ? process.env.REACT_APP_GOOGLE_CLIENT_ID
              : ""
          }
        >
          <AxiosInterceptor>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </AxiosInterceptor>
        </GoogleOAuthProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
reportWebVitals(SendAnalytics);
