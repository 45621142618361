import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "../../auth.css";
import { useNavigate } from "react-router-dom";

import { secure } from "../../../../partial/images";
import { useFetch } from "../../../../interceptors";
const ConfirmPassword = ({ data, setForgotStepCount }: any) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<any>("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };
  const handleShowConfirmPassword = () => {
    showConfirmPassword
      ? setShowConfirmPassword(false)
      : setShowConfirmPassword(true);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (password === confirmPassword) {
      if (password.length >= 8) {
        setIsLoading(true);

        const BODY = {
          newPassword: password,
          confirmPassword: confirmPassword,
          _id: data?.userid,
        };
        useFetch
          .post(`/forgotPassword`, BODY)
          .then((res) => {
            setIsLoading(false);
            if (res.status === 200) {
              toast({
                position: "top",
                title: "Password Updated Successfully",
                description: ``,
                status: "success",
                duration: 4000,
                isClosable: true,
              });
              navigate("/login");
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        setError("Password must be at least 8 characters long");
      }
    } else {
      setError("Passwords must match");
    }
  };

  return (
    <>
      <VStack flex={1} justify="center" mx="10px">
        <VStack
          align="center"
          border="1px solid #dadada"
          borderRadius="48px"
          height="auto"
          width="auto"
          padding={{ base: "10px 25px", lg: "72px 132px" }}
          gap="24px"
        >
          <Image
            src={secure}
            height="136px"
            width="136px"
            borderRadius="full"
          />
          <VStack>
            <Text className="from-title">Set New Password</Text>
          </VStack>

          <form onSubmit={handleSubmit}>
            <VStack gap="16px">
              <FormControl>
                <FormLabel fontSize="12px" fontWeight="400">
                  Password
                </FormLabel>
                <InputGroup bg="transparent" h="56px" borderRadius="16px">
                  <InputLeftElement height="56px">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M13.2295 16.2395L13.3799 16.1355C13.8508 15.8099 14.0862 15.6471 14.2968 15.4725C15.4412 14.5241 16.2098 13.202 16.4656 11.7421C16.5127 11.4734 16.5372 11.1892 16.5861 10.6208L16.6114 10.3269C16.6924 9.38597 16.6843 8.43958 16.5873 7.50019L16.5573 7.20949C16.3874 5.56558 15.3782 4.12476 13.887 3.39719C11.4351 2.20094 8.56486 2.20094 6.11297 3.39719C4.62171 4.12476 3.61251 5.56558 3.44271 7.20949L3.41268 7.50019C3.31564 8.43958 3.30757 9.38597 3.38856 10.3269L3.41386 10.6208C3.46279 11.1892 3.48726 11.4734 3.53434 11.7421C3.79016 13.202 4.55878 14.5241 5.70315 15.4725C5.91374 15.6471 6.14923 15.8099 6.62003 16.1355L6.77049 16.2395C7.41059 16.6821 7.73066 16.9035 8.05144 17.0573C9.28272 17.6476 10.7172 17.6476 11.9485 17.0573C12.2693 16.9035 12.5894 16.6821 13.2295 16.2395Z"
                        stroke="#8B8D8F"
                        stroke-width="1.5"
                      />
                      <path
                        d="M7.70831 9.79168L9.37498 11.4583L12.2916 8.33334"
                        stroke="#8B8D8F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </InputLeftElement>
                  <Input
                    height="56px"
                    borderRadius="16px"
                    type={showPassword ? "text" : "password"}
                    placeholder="your password "
                    isRequired={true}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement
                    height="56px"
                    cursor="pointer"
                    onClick={handleShowPassword}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M3.25617 7.20946C6.28135 2.04131 13.7186 2.04131 16.7438 7.20946C17.7521 8.93188 17.7521 11.0681 16.7438 12.7906C13.7187 17.9587 6.28135 17.9587 3.25617 12.7906C2.24794 11.0681 2.24794 8.93188 3.25617 7.20946Z"
                        stroke="#8B8D8F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.9639 10.0506C12.9639 11.7135 11.6364 13.0611 9.99953 13.0611C8.36266 13.0611 7.03609 11.7135 7.03609 10.0506C7.03609 8.38678 8.36266 7.03917 9.99953 7.03917C11.6364 7.03917 12.9639 8.38678 12.9639 10.0506Z"
                        stroke="#8B8D8F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel fontSize="12px" fontWeight="400">
                  Confirm Password
                </FormLabel>
                <InputGroup bg="transparent" h="56px" borderRadius="16px">
                  <InputLeftElement height="56px">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M13.2295 16.2395L13.3799 16.1355C13.8508 15.8099 14.0862 15.6471 14.2968 15.4725C15.4412 14.5241 16.2098 13.202 16.4656 11.7421C16.5127 11.4734 16.5372 11.1892 16.5861 10.6208L16.6114 10.3269C16.6924 9.38597 16.6843 8.43958 16.5873 7.50019L16.5573 7.20949C16.3874 5.56558 15.3782 4.12476 13.887 3.39719C11.4351 2.20094 8.56486 2.20094 6.11297 3.39719C4.62171 4.12476 3.61251 5.56558 3.44271 7.20949L3.41268 7.50019C3.31564 8.43958 3.30757 9.38597 3.38856 10.3269L3.41386 10.6208C3.46279 11.1892 3.48726 11.4734 3.53434 11.7421C3.79016 13.202 4.55878 14.5241 5.70315 15.4725C5.91374 15.6471 6.14923 15.8099 6.62003 16.1355L6.77049 16.2395C7.41059 16.6821 7.73066 16.9035 8.05144 17.0573C9.28272 17.6476 10.7172 17.6476 11.9485 17.0573C12.2693 16.9035 12.5894 16.6821 13.2295 16.2395Z"
                        stroke="#8B8D8F"
                        stroke-width="1.5"
                      />
                      <path
                        d="M7.70831 9.79168L9.37498 11.4583L12.2916 8.33334"
                        stroke="#8B8D8F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </InputLeftElement>
                  <Input
                    height="56px"
                    borderRadius="16px"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="confirm password "
                    isRequired={true}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <InputRightElement
                    height="56px"
                    cursor="pointer"
                    onClick={handleShowConfirmPassword}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M3.25617 7.20946C6.28135 2.04131 13.7186 2.04131 16.7438 7.20946C17.7521 8.93188 17.7521 11.0681 16.7438 12.7906C13.7187 17.9587 6.28135 17.9587 3.25617 12.7906C2.24794 11.0681 2.24794 8.93188 3.25617 7.20946Z"
                        stroke="#8B8D8F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.9639 10.0506C12.9639 11.7135 11.6364 13.0611 9.99953 13.0611C8.36266 13.0611 7.03609 11.7135 7.03609 10.0506C7.03609 8.38678 8.36266 7.03917 9.99953 7.03917C11.6364 7.03917 12.9639 8.38678 12.9639 10.0506Z"
                        stroke="#8B8D8F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              {error && (
                <Text color="red" fontSize="12px">
                  {error}
                </Text>
              )}
              <HStack w="full">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.10228 2.604C6.2974 2.4086 6.2974 2.09178 6.10228 1.89637C5.90716 1.70097 5.59082 1.70097 5.3957 1.89637L4.00258 3.29156L2.60461 1.89704C2.4091 1.70202 2.09213 1.70202 1.89663 1.89704C1.70112 2.09206 1.70112 2.40825 1.89663 2.60327L3.296 3.99919L1.90295 5.39432C1.70783 5.58972 1.70783 5.90654 1.90295 6.10195C2.09806 6.29736 2.41441 6.29736 2.60953 6.10195L4.00398 4.70542L5.39646 6.09447C5.59196 6.28949 5.90894 6.28949 6.10444 6.09447C6.29994 5.89945 6.29994 5.58325 6.10444 5.38823L4.71056 3.99779L6.10228 2.604Z"
                    fill="#737098"
                  />
                </svg> */}
                <Text className="password-desc">* At least 8 characters</Text>
              </HStack>
              <Button
                bg="#8F4BF6"
                h="auto"
                w={{ base: "auto", md: "344px" }}
                p="18px 32px"
                color="white"
                borderRadius="20px"
                colorScheme="purple"
                type="submit"
                isLoading={isLoading}
              >
                Submit
              </Button>
            </VStack>
          </form>
        </VStack>
        <Flex align="center" gap="4px" pt="40px" mb="10px">
          <Text fontSize="14px" fontWeight="400">
            Need help?
          </Text>
          <Text
            fontSize="14px"
            fontWeight="500"
            color="#090B0C"
            letterSpacing="-0.14px"
            lineHeight="20px"
            cursor="pointer"
            _hover={{ color: "blue" }}
          >
            Contact Support
          </Text>
        </Flex>
      </VStack>
    </>
  );
};

export default ConfirmPassword;
