import { Box, Flex, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailVerification from "./emailVerify/email";
import OtpVerification from "./emailVerify/oTpVerification";
import { group1, group2, image } from "../../../partial/images";
import { Carousel } from "react-responsive-carousel";
import ConfirmPassword from "./emailVerify/confirmPassword";
import { LiaStarSolid } from "react-icons/lia";
import "../auth.css";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const ImageData = [
    {
      image: image,
      review: `"I’m very pleased with the answer and appreciate the help. I’ll be sure to Tell everyone about your site! Thank you."`,
      by: "Jan R",
      id: 1,
    },
    {
      image: group1,
      review: `"I needed a timely answer for my pup and PetVet was absolutely amazing. Thank you so much."`,
      by: "Mike B",
      id: 2,
    },
    {
      image: group2,
      review: `"It is incredible that this resource is free. I needed help and got it - all in less than 1 minute. Thank You So Much!!"`,
      by: "Trisha M",
      id: 3,
    },
  ];
  const [forgotStepCount, setForgotStepCount] = useState(1);
  return (
    <>
      <Flex
        w="full"
        direction={{ base: "column-reverse", lg: "row" }}
        // maxH="100vh"
        h={{ base: "auto", lg: "100vh" }}
        align="center"
        gap={{ base: "20px", lg: "10px" }}
      >
        <VStack
          w={{ base: "full", lg: "544px" }}
          className="flagWidth"
          h={{ base: "auto", lg: "100vh" }}
          bg="#F1F0F9"
          justify="space-evenly"
          gap="20px"
          py="50px"
        >
          <Box className="carousel-width">
            <Carousel
              showArrows={false}
              showStatus={false}
              autoPlay={true}
              infiniteLoop={true}
            >
              {ImageData.map((data) => (
                <VStack key={data?.id} pb="50px">
                  <Image
                    src={data?.image}
                    height="317px"
                    width={{ base: "300px", md: "327px" }}
                  />
                  <VStack gap="16px" width={{ base: "300px", md: "400px" }}>
                    <Text fontSize="16px" fontWeight="400" textAlign="center">
                      {data?.review}
                    </Text>

                    <Flex gap="8px">
                      {[1, 2, 3, 4, 5].map((data) => (
                        <Icon as={LiaStarSolid} color="#DFA600" />
                      ))}
                    </Flex>
                    <Text
                      fontSize="16px"
                      fontWeight="700"
                      color="#737098"
                      textAlign="center"
                    >
                      {data?.by}
                    </Text>
                  </VStack>
                </VStack>
              ))}
            </Carousel>
          </Box>
        </VStack>
        <Flex w="full" mt={{ base: "40px", lg: "0" }}>
          {forgotStepCount === 1 && (
            <EmailVerification
              setData={setData}
              setForgotStepCount={setForgotStepCount}
            />
          )}
          {forgotStepCount === 2 && (
            <OtpVerification
              data={data}
              setForgotStepCount={setForgotStepCount}
            />
          )}
          {forgotStepCount === 3 && (
            <ConfirmPassword
              data={data}
              setForgotStepCount={setForgotStepCount}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default ForgotPassword;
