import { Button, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import {
  downloadAppleStore,
  downloadGooglePlay,
  logo,
  phones,
} from "../../partial/images";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  const handleOpenPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=ai.petvet.app",
      "_blank"
    );
  };
  const handleOpenAppStore = () => {
    window.open(
      "https://apps.apple.com/us/app/petvet-ai/id6468880991",
      "_blank"
    );
  };
  return (
    <>
      <Flex
        direction="column"
        w="full"
        padding={{ base: "10px 20px", md: "20px 40px" }}
        align="center"
        justify="center"
        border="1px solid #F1F0F9"
        borderRadius="24px"
        height={{ base: "auto", md: "80vh" }}
        gap={{ base: "20px", md: "40px" }}
      >
        <VStack gap="10px" w={{ base: "auto", md: "563px" }} mt="10px">
          <Image src={logo} height="auto" w="60px" />

          <Text
            fontSize={{ base: "28px", md: "32px" }}
            fontWeight="700"
            color="#000"
            pt="5px"
          >
            PetVet-AI
          </Text>
          <Text
            fontSize={{ base: "18px", md: "20px" }}
            textAlign="center"
            fontWeight="400"
          >
            Discover Pet Vet - your pet's best friend. Our AI-backed app offers
            expert care and answers at your fingertips. Download now for
            top-tier pet health.
          </Text>
        </VStack>
        <HStack display={{ base: "flex", lg: "none" }}>
          <Button
            onClick={() => navigate("/AiChat")}
            bg="#8F4BF6"
            color="white"
            colorScheme="purple"
            padding={{ base: "10px", md: "20px" }}
            borderRadius="14px"
          >
            Chat With AI Vet
          </Button>
          <Button
            onClick={() => navigate("/chat")}
            bg="#EC694E"
            color="white"
            colorScheme="orange"
            padding={{ base: "10px", md: "20px" }}
            borderRadius="14px"
          >
            Chat With IRL Vet
          </Button>
        </HStack>
        <Image src={phones} height="244px" width="254px" />
        <Flex direction="column" w="full" gap="35px" align="center">
          <Text fontSize="24px" fontWeight="700" color="#000">
            Download the App here!
          </Text>
          <HStack w="full" justify="center">
            <Image
              src={downloadAppleStore}
              height={{ base: "50px", md: "60px" }}
              width={{ base: "150px", md: "200px" }}
              cursor="pointer"
              onClick={handleOpenAppStore}
            />
            <Image
              src={downloadGooglePlay}
              height={{ base: "50px", md: "60px" }}
              width={{ base: "150px", md: "200px" }}
              cursor="pointer"
              onClick={handleOpenPlayStore}
            />
          </HStack>
        </Flex>
      </Flex>
    </>
  );
};

export default LandingPage;
