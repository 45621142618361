import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar";
import { useCurrentUser } from "../partial/utils/user";

const PrivateRoutes = () => {
  const currentUser = useCurrentUser();

  return currentUser?.token ? (
    <Sidebar children={<Outlet />} />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
