import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { noImageDog } from "../../../../partial/images";
import { usePetsStore } from "../../../../store";
import ReactGA from "react-ga4";
import "../chat.css";
const Inbox = ({ setSelectedUser }: any) => {
  const { fetchPets, petsData } = usePetsStore();
  useEffect(() => {
    fetchPets();
  }, []);
  const [activeClass, setActiveClass] = useState<number>();
  return (
    <>
      <VStack
        px="1px"
        width={{ base: "full", lg: "253px" }}
        bg="#F1F0F9"
        borderRadius="22px"
        minH={{ base: "70vh", lg: "85vh" }}
        maxH={{ base: "70vh", lg: "85vh" }}
        overflowY="auto"
        // overflowX="hidden"
        padding="20px 15px"
        align="start"
      >
        {petsData.map((pet: any) => (
          <HStack
            cursor="pointer"
            height="52px"
            width={{ base: "250px", lg: "180px" }}
            p="6px 16px 6px 6px"
            borderRadius="59px"
            border="1px solid #CBD5E0"
            color="black"
            bg="#E6F0FA"
            fontWeight="500"
            key={pet?._id}
            gap="6px"
            padding="6px 16px 6px 6px"
            onClick={() => {
              setActiveClass(pet);
              setSelectedUser(pet);
              ReactGA.event({
                category: "Chat",
                action: "AI_Chat_Initialization",
                label: "Chat Initialized",
              });
            }}
            className={activeClass === pet ? "selected-chat" : ""}
          >
            <Image
              src={pet?.petImage || noImageDog}
              borderRadius="full"
              height="40px"
              width="40px"
            />
            <Text
              fontSize="14px"
              fontWeight="600"
              display={{ base: "none", lg: "flex" }}
            >
              {pet.petName}
            </Text>
            <Text
              fontSize="14px"
              fontWeight="600"
              display={{ base: "flex", lg: "none" }}
            >
              Click to chat about {pet.petName}
            </Text>
          </HStack>
        ))}
      </VStack>
    </>
  );
};

export default Inbox;
