import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Resolver } from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useFetch } from "../../../interceptors";
import { group1, group2, image, logo } from "../../../partial/images";
import { usePetsStore } from "../../../store";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import FacebookLogin from "@greatsumini/react-facebook-login";
import ReactGA from "react-ga4";
import { useGoogleLogin } from "@react-oauth/google";
import { LiaStarSolid } from "react-icons/lia";
import "../auth.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
type FormValues = {
  email: string;
  password: string;
};
const resolver: Resolver<FormValues> = async (values) => {
  return {
    values: values.email ? values : {},
    errors: !values.email
      ? {
          email: {
            type: "required",
            message: "This is required.",
          },
        }
      : {},
  };
};
const LogIn = () => {
  const { fetchPets, petsData } = usePetsStore();
  const navigate = useNavigate();

  const ImageData = [
    {
      image: image,
      review: `"I’m very pleased with the answer and appreciate the help. I’ll be sure to Tell everyone about your site! Thank you."`,
      by: "Jan R",
      id: 1,
    },
    {
      image: group1,
      review: `"I needed a timely answer for my pup and PetVet was absolutely amazing. Thank you so much."`,
      by: "Mike B",
      id: 2,
    },
    {
      image: group2,
      review: `"It is incredible that this resource is free. I needed help and got it - all in less than 1 minute. Thank You So Much!!"`,
      by: "Trisha M",
      id: 3,
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);

  const fbId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const handleShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };
  const { register, handleSubmit } = useForm<FormValues>({ resolver });
  useEffect(() => {
    if (!isChecking) {
      if (petsData.length > 0) {
        navigate("/home");
      } else {
        navigate("/addpet");
      }
    }
  }, [isChecking, petsData]);

  const onSubmit = handleSubmit(async (value) => {
    setIsLoading(true);
    await useFetch
      .post(`/login`, {
        email: value.email,
        password: value.password,
      })
      .then(async (res) => {
        if (res.status === 200) {
          ReactGA.event({
            category: "User",
            action: "login",
            label: "User Logged In",
          });
          res.data.data &&
            localStorage.setItem("user", JSON.stringify(res.data.data));
          localStorage.setItem(
            "userToken",
            JSON.stringify(res.data.data.token)
          );
          await fetchPets();
          setIsLoading(false);
          setIsChecking(false);
          toast({
            position: "top",
            title: `${res.data.message}`,
            description: "",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response.status === 423) {
          const currentTime = error?.response?.data.currentTime;
          const expireTime = error?.response?.data.expireTime;
          const currentTimeMoment = moment(currentTime, "h:mm A");
          const expireTimeMoment = moment(expireTime, "h:mm A");

          // Calculate time difference in minutes
          const timeDifferenceMinutes = expireTimeMoment.diff(
            currentTimeMoment,
            "minutes"
          );
          const pluralizedMinute =
            timeDifferenceMinutes > 1 ? "minutes" : "minute";

          toast({
            position: "top",
            title: `Your account has been temporarily locked due to multiple failed login attempts. Please try again after  ${timeDifferenceMinutes} ${pluralizedMinute} . `,
            description: "",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        } else if (error.response.status === 451) {
          const currentTime = error?.response?.data.currentTime;
          const expireTime = error?.response?.data.expireTime;
          const currentTimeMoment = moment(currentTime, "h:mm A");
          const expireTimeMoment = moment(expireTime, "h:mm A");

          // Calculate time difference in minutes
          const timeDifferenceMinutes = expireTimeMoment.diff(
            currentTimeMoment,
            "minutes"
          );
          const pluralizedMinute =
            timeDifferenceMinutes > 1 ? "minutes" : "minute";

          toast({
            position: "top",
            title: `Your account has been temporarily locked due to multiple failed login attempts. Please try again after  ${timeDifferenceMinutes} ${pluralizedMinute} . `,
            description: "",
            status: "error",
            duration: 3000,
            isClosable: false,
          });
        } else {
          toast({
            position: "top",
            title: `${error?.response?.data?.message}`,
            description: "",
            status: "error",
            duration: 3000,
            isClosable: false,
          });
        }
      });
  });

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      await useFetch
        .post(`/googleLogin`, {
          googleAccessToken: codeResponse.access_token,
        })
        .then(async (res) => {
          if (res.status === 200) {
            ReactGA.event({
              category: "User",
              action: "login",
              label: "User Logged In",
            });

            res.data.data &&
              localStorage.setItem("user", JSON.stringify(res.data.data));
            localStorage.setItem(
              "userToken",
              JSON.stringify(res.data.data.token)
            );
            await fetchPets();
            setIsChecking(false);

            toast({
              position: "top",
              title: `${res.data.message}`,
              description: "",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          toast({
            position: "top",
            title: `${error?.response?.data?.message}`,
            description: "",
            status: "error",
            duration: 3000,
            isClosable: false,
          });
        });
    },
  });
  const handleFacebookSuccessLogin = (response: any) => {
    useFetch
      .post(`/facebookLogin`, {
        fbUserId: response?.userID,
      })
      .then(async (res) => {
        if (res.status === 200) {
          ReactGA.event({
            category: "User",
            action: "login",
            label: "User Logged In",
          });
          res.data.data &&
            localStorage.setItem("user", JSON.stringify(res.data.data));
          localStorage.setItem(
            "userToken",
            JSON.stringify(res.data.data.token)
          );
          await fetchPets();
          setIsChecking(false);

          toast({
            position: "top",
            title: `${res.data.message}`,
            description: "",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        toast({
          position: "top",
          title: `${error?.response?.data?.message}`,
          description: "",
          status: "error",
          duration: 3000,
          isClosable: false,
        });
        console.log(error);
      });
  };

  return (
    <>
      <Flex
        w="full"
        direction={{ base: "column-reverse", lg: "row" }}
        // maxH="100vh"
        h={{ base: "auto", lg: "100vh" }}
        align="center"
        gap={{ base: "20px", lg: "10px" }}
      >
        <VStack
          w={{ base: "full", lg: "544px" }}
          className="flagWidth"
          h={{ base: "auto", lg: "100vh" }}
          bg="#F1F0F9"
          justify="space-evenly"
          gap="20px"
          py="50px"
          // flex={1}
        >
          <Box className="carousel-width">
            <Carousel
              showArrows={false}
              showStatus={false}
              autoPlay={true}
              showThumbs={false}
              infiniteLoop={true}
            >
              {ImageData.map((data) => (
                <VStack key={data?.id} pb="50px">
                  <Image
                    src={data?.image}
                    height="317px"
                    width={{ base: "300px", md: "327px" }}
                  />
                  <VStack gap="16px" width={{ base: "300px", md: "400px" }}>
                    <Text fontSize="16px" fontWeight="400" textAlign="center">
                      {data?.review}
                    </Text>

                    <Flex gap="8px">
                      {[1, 2, 3, 4, 5].map((data) => (
                        <Icon as={LiaStarSolid} color="#DFA600" />
                      ))}
                    </Flex>
                    <Text
                      fontSize="16px"
                      fontWeight="700"
                      color="#737098"
                      textAlign="center"
                    >
                      {data?.by}
                    </Text>
                  </VStack>
                </VStack>
              ))}
            </Carousel>
          </Box>
        </VStack>

        <VStack
          flex={1}
          justify="center"
          mt="40px"
          mx="10px"
          height={{ base: "100vh", lg: "auto" }}
        >
          <VStack
            align="center"
            border="1px solid #dadada"
            borderRadius="48px"
            height="auto"
            width="auto"
            padding={{ base: "10px 25px", lg: "58px 132px" }}
            gap="24px"
          >
            <VStack gap="10px">
              <Image src={logo} height="auto" w="80px" />

              <Text fontSize="26px" fontWeight="700" pt="5px">
                PetVet-AI
              </Text>
              <Text fontSize="24px" fontWeight="700">
                Login/Registration
              </Text>
            </VStack>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit(e);
              }}
            >
              <VStack gap="24px">
                <FormControl>
                  <FormLabel fontSize="12px" fontWeight="400">
                    Email
                  </FormLabel>
                  <InputGroup bg="transparent" h="56px" borderRadius="16px">
                    <InputLeftElement fontSize="20px" height="56px">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M3.58519 5.417L8.40204 8.85837C9.35793 9.54129 10.642 9.54129 11.5979 8.85837L16.4148 5.417M2.40447 12.626C1.97626 10.9015 1.97626 9.09846 2.40447 7.37396C2.96502 5.11653 4.75499 3.37111 7.02544 2.86802L7.40367 2.78421C9.11381 2.40526 10.8862 2.40526 12.5963 2.78421L12.9745 2.86802C15.245 3.37111 17.0349 5.11653 17.5955 7.37396C18.0237 9.09846 18.0237 10.9015 17.5955 12.626C17.0349 14.8835 15.245 16.6289 12.9745 17.132L12.5963 17.2158C10.8861 17.5947 9.11381 17.5947 7.40367 17.2158L7.02544 17.132C4.75499 16.6289 2.96502 14.8835 2.40447 12.626Z"
                          stroke="#8B8D8F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                    </InputLeftElement>
                    <Input
                      height="56px"
                      borderRadius="16px"
                      type="text"
                      placeholder="name@example.com"
                      {...register("email")}
                      isRequired={true}
                    />
                  </InputGroup>
                  {/* {errors?.email && (
                    <Text color="#EC694E" fontWeight="500" fontSize="12px">
                      {errors.email.message}
                    </Text>
                  )} */}
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="12px" fontWeight="400">
                    Password
                  </FormLabel>
                  <InputGroup bg="transparent" h="56px" borderRadius="16px">
                    <InputLeftElement height="56px">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M13.2295 16.2395L13.3799 16.1355C13.8508 15.8099 14.0862 15.6471 14.2968 15.4725C15.4412 14.5241 16.2098 13.202 16.4656 11.7421C16.5127 11.4734 16.5372 11.1892 16.5861 10.6208L16.6114 10.3269C16.6924 9.38597 16.6843 8.43958 16.5873 7.50019L16.5573 7.20949C16.3874 5.56558 15.3782 4.12476 13.887 3.39719C11.4351 2.20094 8.56486 2.20094 6.11297 3.39719C4.62171 4.12476 3.61251 5.56558 3.44271 7.20949L3.41268 7.50019C3.31564 8.43958 3.30757 9.38597 3.38856 10.3269L3.41386 10.6208C3.46279 11.1892 3.48726 11.4734 3.53434 11.7421C3.79016 13.202 4.55878 14.5241 5.70315 15.4725C5.91374 15.6471 6.14923 15.8099 6.62003 16.1355L6.77049 16.2395C7.41059 16.6821 7.73066 16.9035 8.05144 17.0573C9.28272 17.6476 10.7172 17.6476 11.9485 17.0573C12.2693 16.9035 12.5894 16.6821 13.2295 16.2395Z"
                          stroke="#8B8D8F"
                          stroke-width="1.5"
                        />
                        <path
                          d="M7.70831 9.79168L9.37498 11.4583L12.2916 8.33334"
                          stroke="#8B8D8F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </InputLeftElement>
                    <Input
                      height="56px"
                      borderRadius="16px"
                      type={showPassword ? "text" : "password"}
                      placeholder="Your Password "
                      {...register("password")}
                      isRequired={true}
                    />
                    <InputRightElement
                      height="56px"
                      cursor="pointer"
                      onClick={handleShowPassword}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M3.25617 7.20946C6.28135 2.04131 13.7186 2.04131 16.7438 7.20946C17.7521 8.93188 17.7521 11.0681 16.7438 12.7906C13.7187 17.9587 6.28135 17.9587 3.25617 12.7906C2.24794 11.0681 2.24794 8.93188 3.25617 7.20946Z"
                          stroke="#8B8D8F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.9639 10.0506C12.9639 11.7135 11.6364 13.0611 9.99953 13.0611C8.36266 13.0611 7.03609 11.7135 7.03609 10.0506C7.03609 8.38678 8.36266 7.03917 9.99953 7.03917C11.6364 7.03917 12.9639 8.38678 12.9639 10.0506Z"
                          stroke="#8B8D8F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </InputRightElement>
                  </InputGroup>
                  <Text
                    color="#EC694E"
                    fontWeight="500"
                    fontSize="12px"
                    cursor="pointer"
                    onClick={() => navigate("/forgotPassword")}
                    _hover={{ textDecoration: "underline" }}
                    w="fit-content"
                    lineHeight="20px"
                    pt="12px"
                  >
                    Forgot Password?
                  </Text>
                </FormControl>
                <Button
                  bg="#8F4BF6"
                  h="auto"
                  w={{ base: "auto", md: "344px" }}
                  p="18px 32px"
                  color="white"
                  borderRadius="20px"
                  colorScheme="purple"
                  type="submit"
                  isLoading={isLoading}
                >
                  Login
                </Button>
              </VStack>
            </form>
            <HStack w="full" justify="space-evenly">
              <Divider orientation="horizontal" />
              <Text
                fontSize="12px"
                fontWeight="600"
                width="55%"
                textAlign="center"
                letterSpacing="-0.12px"
              >
                Or use social
              </Text>
              <Divider orientation="horizontal" />
            </HStack>
            <HStack>
              <Button
                bg="#F1F0F9"
                borderRadius="full"
                height="56px"
                width="56px"
                fontSize="24px"
                onClick={() => googleLogin()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                    fill="#FAD826"
                  />
                  <path
                    d="M3.15302 7.3455L6.43851 9.755C7.32752 7.554 9.48052 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.15902 2 4.82802 4.1685 3.15302 7.3455Z"
                    fill="#DF4646"
                  />
                  <path
                    d="M12 21.9999C14.583 21.9999 16.93 21.0114 18.7045 19.4039L15.6095 16.7849C14.5718 17.574 13.3038 18.0009 12 17.9999C9.39903 17.9999 7.19053 16.3414 6.35853 14.0269L3.09753 16.5394C4.75253 19.7779 8.11353 21.9999 12 21.9999Z"
                    fill="#22C55E"
                  />
                  <path
                    d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                    fill="#1976D2"
                  />
                </svg>
              </Button>

              <FacebookLogin
                appId={fbId ? fbId : ""}
                fields="name,email,picture"
                onSuccess={(response) => handleFacebookSuccessLogin(response)}
                onFail={() => {
                  toast({
                    position: "top",
                    title: `Login Failed`,
                    description: "",
                    status: "error",
                    duration: 3000,
                    isClosable: false,
                  });
                }}
                onProfileSuccess={() => {
                  // console.log("Get Profile Success!", response);
                }}
                render={({ onClick }) => (
                  <Button
                    bg="#F1F0F9"
                    borderRadius="full"
                    height="56px"
                    width="56px"
                    fontSize="24px"
                    onClick={onClick}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10.5"
                        fill="url(#paint0_linear_85_29313)"
                      />
                      <path
                        d="M15.9103 15.2112L16.3767 12.2476H13.4589V10.3252C13.4589 9.51428 13.8657 8.7233 15.1726 8.7233H16.5V6.20024C16.5 6.20024 15.2959 6 14.1452 6C11.7411 6 10.1712 7.4197 10.1712 9.98883V12.2476H7.5V15.2112H10.1712V22.3759C10.7075 22.458 11.2562 22.5 11.8151 22.5C12.374 22.5 12.9226 22.458 13.4589 22.3759V15.2112H15.9103Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_85_29313"
                          x1="12"
                          y1="1.5"
                          x2="12"
                          y2="22.4377"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#18ACFE" />
                          <stop offset="1" stop-color="#0163E0" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Button>
                )}
              />
            </HStack>
          </VStack>
          <Flex align="center" gap="4px" pt="25px" mb="10px">
            <Text fontSize="14px" fontWeight="400" lineHeight="24px">
              Don’t have an account?
            </Text>
            <Text
              fontSize="14px"
              fontWeight="500"
              cursor="pointer"
              onClick={() => navigate("/signup")}
              _hover={{ textDecoration: "underline", fontWeight: "bold" }}
              lineHeight="20px"
              letterSpacing="-0.14px"
            >
              Sign Up
            </Text>
          </Flex>
        </VStack>
      </Flex>
    </>
  );
};

export default LogIn;
