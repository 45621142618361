import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Img,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IconType } from "react-icons";

import { NavItem, SidebarFooter } from ".";

import { BsPiggyBankFill, BsAspectRatio } from "react-icons/bs";

import {
  logo,
  chat,
  message,
  myPet,
  lock,
  dog1,
  noImageDog,
} from "../../../partial/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LINK_FLEX, RX_CARD } from "../../../partial/utils/constants";
import { GrNext } from "react-icons/gr";
import "../sidebar.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCurrentUser } from "../../../partial/utils/user";
import { useFetch } from "../../../interceptors";
import { usePetsStore } from "../../../store";
export interface LinkItemProps {
  name: string;
  Icon: IconType;
}
interface dataProps {
  petName: string;
  petAge: string;
  petType: string;
  breed: string;
}
const SidebarContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currenUser = useCurrentUser();
  const { fetchPets, petsData } = usePetsStore();

  const [activePet, setActivePet] = useState("");
  const links = [
    {
      logo: chat,
      path: "/AiChat",
      title: "Chat With AI Vet",
      desc: "Get helpful advice (free)",
    },
    {
      logo: message,
      path: "/chat",
      title: "Chat With IRL Vet",
      desc: " Professional medical advice (paid)",
    },
  ];
  const rxCardsLink = [
    {
      id: 1,
      link: "/rxInsuranceCard",
      title: "Pet RX Card",
      desc: "(Free- Sav up to 97% off pet meds)",
    },
    {
      id: 2,
      link: "/petInsuranceQuote",
      title: "Pet Insurance Quotes",
      desc: "(Get competative quotes fast)",
    },
    // {
    //   id: 3,
    //   link: "/",
    //   title: "Pet Food Deals",
    //   desc: "(Top discounts on food)",
    // },
  ];
  useEffect(() => {
    fetchPets();
  }, []);
  return (
    <Flex
      bg="#F1F0F9"
      borderRight="1px"
      borderRightColor="gray.200"
      w="315px"
      display={{ base: "none", lg: "flex" }}
      gap="5"
      h="full"
      flexDir="column"
      position="fixed"
      overflow="scroll"
    >
      <Flex direction="column" flex="4">
        <Flex
          gap="8px"
          h="20"
          alignItems="center"
          mx={{ base: 1, lg: 3 }}
          justifyContent="start"
          ps="20px"
          mt="22px"
          mb="45px"
        >
          <Link to="/home">
            <Img src={logo} height="auto" w="49px" />
          </Link>
          <Link to="/home">
            <Text color="#272655" fontWeight="700" fontSize="16px">
              PetVet-AI
            </Text>
          </Link>
        </Flex>
        <Flex direction="column" px="10px" gap="8px" mb="48px">
          {links.map((link: any, index: number) => (
            <Link to={link.path} key={index}>
              <Flex
                w="95%"
                {...LINK_FLEX}
                className={location.pathname === link.path ? "activeLink" : ""}
              >
                <Image
                  mr="4"
                  fontSize="16"
                  _groupHover={{
                    color: "#fff",
                  }}
                  src={link.logo}
                />
                <HStack w="75%" justify="space-between" align="center">
                  <VStack align="start" gap="1px">
                    <Text color="#272655" fontWeight="700" fontSize="16px">
                      {link.title}
                    </Text>
                    <Text color="#272655" fontWeight="400" fontSize="14px">
                      {link.desc}
                    </Text>
                  </VStack>
                  <Icon as={GrNext} />
                </HStack>
              </Flex>
            </Link>
          ))}
        </Flex>
        <Accordion allowToggle w="full">
          <AccordionItem w="full">
            <AccordionButton w="full" _hover={{ backgroundColor: "none" }}>
              <Flex {...LINK_FLEX}>
                <HStack
                  display={{ base: "none", lg: "flex" }}
                  justify="space-between"
                  align="center"
                >
                  <Image
                    mr="4"
                    fontSize="16"
                    _groupHover={{
                      color: "#fff",
                    }}
                    src={myPet}
                  />
                  <VStack align="start" gap="1px">
                    <Text color="#272655" fontWeight="700" fontSize="16px">
                      My Pets
                    </Text>
                  </VStack>
                </HStack>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel mb={4}>
              <VStack
                align="end"
                width="full"
                maxH="150px"
                // paddingBottom="15px"
                overflowY="scroll"
                paddingLeft="20px"
                overflowX="hidden"
              >
                {petsData.map((data: any, i: any) => (
                  <Flex
                    key={data._id}
                    {...LINK_FLEX}
                    p="6px 6px 6px 6px"
                    w="182px"
                    h="52px"
                    className={
                      (activePet === data._id &&
                        location.pathname === "/myPet") ||
                      (activePet === data._id &&
                        location.pathname === "/editPet")
                        ? "activePet"
                        : ""
                    }
                    border="1px solid #E5E4F9"
                    color="black"
                    bg="#E6F0FA"
                    borderRadius="59px"
                    onClick={() => {
                      setActivePet(data._id);
                      navigate("/myPet", { state: { data: data } });
                    }}
                  >
                    <Image
                      mr="4"
                      fontSize="16"
                      _groupHover={{
                        color: "#fff",
                      }}
                      height="40px"
                      width="40px"
                      borderRadius="full"
                      src={data?.petImage || noImageDog}
                    />
                    <HStack
                      display={{ base: "none", lg: "flex" }}
                      justify="space-between"
                      align="center"
                    >
                      <VStack align="start" gap="1px">
                        <Text fontWeight="700" fontSize="16px">
                          {data.petName}
                        </Text>
                      </VStack>
                    </HStack>
                  </Flex>
                ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
      <VStack flex={2}>
        {rxCardsLink.map((data) => (
          <Link to={data?.link} key={data?.id}>
            <Flex
              // w="85%"
              {...RX_CARD}
              className={location.pathname === data?.link ? "activeRxCard" : ""}
            >
              <Flex direction="column" align="center" color="#FFFFFF">
                <Text
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="20px"
                  letterSpacing="-0.16px"
                >
                  {data?.title}
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="20px"
                  letterSpacing="-0.14px"
                >
                  {data?.desc}
                </Text>
              </Flex>
            </Flex>
          </Link>
        ))}
      </VStack>
      {/* <SidebarFooter /> */}
    </Flex>
  );
};

export default SidebarContent;
