import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import "./faqs.css";
const FAQs = () => {
  const FAQquestions = [
    {
      title: "How does PetVet.ai work?",
      des: "PetVet.ai is like your digital pet-savvy buddy. We've packed it with knowledge from vast veterinary medical records and expert insights. Just share your pet's symptoms and quirks, and our system, with its smart algorithms, will offer potential insights and advice. It's like having a chat with a friend who's browsed through tons of vet books!",
      des2: "But remember, while we're here to lend a helping paw, nothing beats the expertise of your local vet. So, if ever in doubt, always give them a shout!",
    },
    {
      title: "Does this replace my regular veterinarian?",
      des: "PetVet.ai is like a knowledgeable pal you chat with before seeing your vet. While we're brimming with insights and can help you frame those sometimes-nervy questions for your vet, we're not a replacement for their hands-on expertise. Think of us as a guide helping you understand when Fluffy's itch is just an itch or when it's time to rush to the vet straight away. Your regular veterinarian is invaluable, and our aim is to complement their care by helping you make informed decisions for your furry friend's wellbeing.",
      des2: "Always trust your gut and when in doubt, a check-up with your vet is the way to go. After all, better safe than sorry, right?",
    },
    {
      title: "How accurate is the information provided by the app?",
      des: "At PetVet.ai, we truly care about giving you reliable insights for your pet's well-being. Our system taps into ChatGPT's latest API, powered by real veterinary data, so you're getting a blend of tech smarts and genuine pet care knowledge. That said, while we aim to be a helpful buddy in your pet care journey, remember, every pet is unique!",
      des2: "For those especially puzzling or serious moments, it's always a good call to visit your trusted vet. Think of us as your go-to pet encyclopedia with a techy twist, and your vet as the hands-on expert who knows your pet inside out!",
    },
    {
      title: "What animals can I ask questions about?",
      des: "Whether you're curious about your purring cat, your chirpy bird, or even your splashy fish, PetVet.ai has got you covered. We're here for questions about dogs, cats, birds, fish, reptiles, rodents, horses, amphibians, chickens, ducks, goats, sheep, and pigs. So, from whiskers to feathers, scales to hooves, we're here to help with a treasure trove of knowledge.",
    },
    {
      title: "If my pet has a life threatening emergency, what should I do?",
      des: "Oh dear, we understand how frightening it can be when our pet family members are in distress! In any life-threatening situation, PetVet.ai strongly recommends reaching out to your local or emergency vet immediately. They have the expertise and equipment to provide urgent care and make the best decisions for your beloved pet.",
      des2: "We're here to help with insights and information, but in emergencies, there's no substitute for hands-on professional care. Always trust your instincts and act swiftly to keep your pet safe. Wishing your little companion all the best! ❤️",
    },
    {
      title: "How can I get the best possible answers from PetVet.ai?",
      des: "To help us assist you in the best way possible, it's super helpful if you share as much detail and history about your pet as you can. Whether it's their last check-up, that funny thing they ate last week, or a recent trip to the vet or emergency room, every bit of information helps. Think of us as your digital pet detective - the more clues you provide, the closer we get to solving the puzzle!      ",
      des2: "Your pet's story is unique, and by sharing it, you'll help us offer the most tailored advice possible. And as always, remember we're here to guide and assist, but your vet's hands-on expertise is invaluable for complex concerns.",
    },
  ];
  return (
    <>
      <VStack w="full" align="start">
        <Text fontSize="24px" fontWeight="700" p="10px 30px">
          FAQ - PetVet.Ai
        </Text>
        <VStack align="start" p="1px 30px" gap="3px">
          <Text fontSize="20px" fontWeight="700">
            Where does PetVet.Ai get its information from?
          </Text>
          <Text fontSize="16px" fontWeight="400">
            PetVet.Ai uses Chat GPT to return results
          </Text>
        </VStack>
        <Accordion minW="full" allowMultiple px="10px">
          {FAQquestions.map((data, index) => (
            <AccordionItem
              minW="full"
              key={index}
              borderRadius="20px"
              mb="12px"
              boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04);"
              _hover={{ borderRadius: "20px !important" }}
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton height="72px">
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontSize="16px" fontWeight="700">
                          {data.title}
                        </Text>
                      </Box>
                      {isExpanded ? (
                        <Icon as={AiOutlineMinus} fontSize="24px" />
                      ) : (
                        <Icon as={AiOutlinePlus} fontSize="24px" />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize="14px" fontWeight="400">
                    <Flex direction="column" w="full">
                      <Text>{data.des}</Text>
                      {data.des2 && <Text>{data.des2}</Text>}
                    </Flex>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </>
  );
};

export default FAQs;
